import React, { useState, useEffect } from "react";
import {
  SiVisa,
  SiMastercard,
  SiAmericanexpress,
  SiDiscover,
  SiJcb,
} from "react-icons/si";
import { FaCreditCard } from "react-icons/fa";
import styles from "./CardTypes.module.css";

const CardTypes = ({
  selectedCardId,
  setSelectedCardId,
  cardInformation,
}) => {
  const [cards, setCards] = useState([]);
  useEffect(() => {
    if (cardInformation && cardInformation.paymentMethod_id) {
      const [expMonth, expYear] = cardInformation.exp.split("/");

      const newCard = {
        id: cardInformation.paymentMethod_id,
        brand: cardInformation.brand,
        last4: cardInformation.last4,
        exp_month: expMonth,
        exp_year: expYear,
      };
      setCards([newCard]);
      setSelectedCardId(newCard.id); // Preselect the existing card
    }
  }, [cardInformation]);

  const getCardIcon = (brand) => {
    const iconClassName = styles.cardIcon;
    switch (brand) {
      case "visa":
        return <SiVisa className={iconClassName} />;
      case "mastercard":
        return <SiMastercard className={iconClassName} />;
      case "amex":
        return <SiAmericanexpress className={iconClassName} />;
      case "discover":
        return <SiDiscover className={iconClassName} />;
      case "jcb":
        return <SiJcb className={iconClassName} />;
      default:
        return <FaCreditCard className={iconClassName} />;
    }
  };

  return (
    <>
      {cards.map((card) => (
        <div key={card.id}>
          <div
            onClick={() => setSelectedCardId(card.id)}
            className={`${styles.cardContainer} ${
              selectedCardId === card.id ? styles.cardContainerSelected : ""
            }`}
          >
            <div className={styles.cardInfo}>
              <div className={styles.cardIconContainer}>
                {getCardIcon(card.brand)}
              </div>
              <div>
                <span>Ending in {card.last4}</span>
                <span className={styles.cardExpiry}>
                  <span>
                    {card.exp_month}/{card.exp_year}
                  </span>
                </span>
              </div>
            </div>
            <div className={styles.cardActions}>
              {selectedCardId === card.id && (
                <span style={{ color: "#1B7AF6", fontSize: "12px" }}>
                  Default
                </span>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CardTypes;
