import React, {useState, useEffect} from 'react';

// styles
import styles from './coachAnalytics.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {GraphPlaceholder} from "../../graphPlaceholder/graphPlaceholder";
import MemberGrowthGraph from "./memberGrowthGraph/memberGrowthGraph";
import MessagingGraph from "./messagingGraph/messagingGraph";
import SchedulingGraph from "./schedulingGraph/schedulingGraph";
import Calendars from "./calendars/calendars";
import subDays from "date-fns/subDays";
import startOfToday from "date-fns/startOfToday";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import {
     fetchCoachCalendarStats,
    fetchCoachMemberGrowthData,
    fetchCoachMessageData, fetchCoachMessageResponseTime,
    fetchCoachMessagingStats,
    fetchCoachOpenedThreads,
    fetchCoachReopenedThreads, fetchCoachSchedulingData
} from "../../../store/actions/analyticsActions";
import {SecondaryInfoAlert} from "../../alerts/alerts";

const CoachAnalytics = props => {
    // props
    const {filters} = props;
    // local
    const [date, setDate] = useState({
        start: subDays(startOfToday(), 30),
        end: new Date()
    });
    const [memberGrowthData, setMemberGrowthData] = useState(null);
    const [messagingData, setMessagingData] = useState(null);
    const [messagingStats, setMessagingStats] = useState(null);
    const [newThreads, setNewThreads] = useState(null);
    const [reopened, setReopened] = useState(null);
    const [responseTime, setResponseTime] = useState(null);
    const [unanswered, setUnanswered] = useState(null);
    const [scheduleData, setScheduleData] = useState(null);
    const [calendars, setCalendars] = useState(null);
    const [scheduleTotal, setScheduleTotal] = useState(null);
    const [query, setQuery] = useState({
        start: date.start,
        end: date.end,
        organizationId: filters.organization,
        statusId: filters.status,
        gender: filters.gender,
        ageGroup: filters.ageGroup,
        coach: filters.coach,
    });

    // on first render
    useEffect(() => {
        if (filters.coach) runQuery();
    }, [query]);

    useDidMountEffect(() => {
        const newQuery = {
            start: date.start,
            end: date.end,
            organizationId: filters.organization,
            statusId: filters.status,
            gender: filters.gender,
            ageGroup: filters.ageGroup,
            coach: filters.coach,
            rank: filters.rank,
        };
        setQuery(newQuery);
    }, [date, filters]);


    // functions
    function runQuery() {
        if (!filters.coach) return;
        getMemberGrowthData();
        getMessagingData();
        getMessagingStats();
        getReplyInfo();
        getSchedulingData();
        getCalendarInfo();
    }

    async function getMemberGrowthData() {
        try {
            const growth = await fetchCoachMemberGrowthData(query);
            setMemberGrowthData(growth);
        } catch (e) {
            console.log(e);
        }
    }

    async function getMessagingData() {
        try {
            const data = await fetchCoachMessageData(query);
            setMessagingData(data);
        } catch (e) {
            console.log(e);
        }
    }

    async function getMessagingStats() {
        try {
            const stats = await fetchCoachMessagingStats(query);
            setMessagingStats(stats);
        } catch (e) {
            console.log(e);
        }
    }

    async function getReplyInfo() {
        try {
            const newThreads = await fetchCoachOpenedThreads(query);
            const reopened = await fetchCoachReopenedThreads(query);
            const stats = await fetchCoachMessageResponseTime(query);
            setTimeout(() => {
                setNewThreads(newThreads);
                setReopened(reopened ? reopened : 0);
                setResponseTime(secondsToHms(stats.average_response_time));
                setUnanswered(stats.no_reply_percent);
            }, 1500);
        } catch (e) {
            console.log(e);
        }
    }

    async function getCalendarInfo() {
        try {
            const schedules = await fetchCoachCalendarStats(query);
            let total = 0;
            if (Array.isArray(schedules)) {
                total = schedules.reduce((prev, curr) => {
                    return prev + (curr.duration * curr.appointments);
                }, 0);
            }
            setCalendars(Array.isArray(schedules) ? schedules : []);
            setScheduleTotal(total);
        } catch (e) {
            console.log(e);
        }
    }

    async function getSchedulingData() {
        try {
            const schedules = await fetchCoachSchedulingData(query);
            setScheduleData(schedules);
        } catch (e) {
            console.log(e);
        }
    }

    function handleDate(e, range) {
        const val = e.target.value;
        let parsedDate = parse(val, 'yyyy-MM-dd', new Date());
        if (range === 'start') {
            parsedDate = startOfDay(parsedDate);
            return setDate({...date, start: parsedDate});
        } else {
            parsedDate = endOfDay(parsedDate);
            return setDate({...date, end: parsedDate});
        }
    }

    function secondsToHms(d) {
        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);
        return `${h > 0 ? h+':' : ''}${m > 0 ? m : 0}:${s > 9 ? s : '0'+s}`;
    }

    if (!filters.coach) {
        return (
            <div>
                <SecondaryInfoAlert>
                    <p className='m:0'>Select a Coach from the filter to see their analytics</p>
                </SecondaryInfoAlert>
            </div>
        )
    }

    return (
        <div>
            <h3 className={styles.heading}> Coach Analytics</h3>
            <form className={styles.filter}>
                <div>
                    <label>Choose Date Range</label>
                    <div className={`form-group ${styles.dateRange}`} data-testID="date-range-coachA">
                        <input
                            max={format(subDays(date.end, 1), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.start, 'yyyy-MM-dd', {})}
                            onChange={e => handleDate(e, 'start')}
                            onKeyDown={e => e.preventDefault()}
                        />
                        <span className='p-r:1 p-l:1'>to</span>
                        <input
                            min={format(addDays(date.start, 1), 'yyyy-MM-dd')}
                            max={format(new Date(), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.end, 'yyyy-MM-dd')}
                            onChange={e => handleDate(e, 'end')}
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>

                </div>
            </form>

            <BoxContainer className={styles.memberGrowthGraph} data-testID="member-growth-coachA">
                <div className={styles.heading2}>Member Growth</div>
                <MemberGrowthGraph data={memberGrowthData}/>
            </BoxContainer>

            <BoxContainer className={styles.messagingGraphRow} data-testID="messaging-coachA">
                <div className={styles.graph}>
                    <div className={styles.heading2}>Messaging</div>
                    <MessagingGraph data={messagingData}/>
                </div>
                <div className={styles.stats}>
                    <div className={styles.singleStat}>
                        <p className={`${styles.subLabel} m:0`}>{messagingStats ? messagingStats.sent : "-"}</p>
                        <h5>Sent</h5>
                    </div>
                    <div className={styles.singleStat}>
                        <p className={`${styles.subLabel} m:0`}>{messagingStats ? messagingStats.received : "-"}</p>
                        <h5>Received</h5>
                    </div>
                    <div className={styles.singleStat}>
                        <p className={`${styles.subLabel} m:0`}>{messagingStats ? messagingStats.total : "-"}</p>
                        <h4>Total</h4>
                    </div>
                </div>
            </BoxContainer>

            <div className={styles.messagingRow}>
                <BoxContainer className={`${styles.boxesOuter} ${styles.inner}`} data-testID="messaging-threads-coachA"> 
                    {newThreads !== null ?
                        <div className={styles.container}>
                            <div className={styles.mainLabel}>{newThreads}</div>
                            <div className={styles.subLabel}>New Threads</div>
                        </div>
                        :
                        <div className={styles.container}>
                            <GraphPlaceholder/>
                        </div>
                    }
                </BoxContainer>
                <BoxContainer className={`${styles.boxesOuter} ${styles.inner}`} data-testID="messaging-re-threads-coachA">
                    { reopened !== null ?
                        <div className={styles.container}>
                            <div className={styles.mainLabel}>{reopened}</div>
                            <div className={styles.subLabel}>Reopened Threads</div>
                        </div>
                        :
                        <div className={styles.container}>
                            <GraphPlaceholder/>
                        </div>
                    }
                </BoxContainer>
                <BoxContainer className={`${styles.boxesOuter} ${styles.inner}`} data-testID="messaging-avg-response-coachA">
                    { responseTime !== null ?
                        <div className={styles.container}>
                            <div className={styles.mainLabel}>{responseTime}</div>
                            <div className={styles.subLabel}>Avg Response Time</div>
                        </div>
                        :
                        <div className={styles.container}>
                            <GraphPlaceholder/>
                        </div>
                    }
                </BoxContainer>
                <BoxContainer className={`${styles.boxesOuter} ${styles.inner}`} data-testID="messaging-no-reply-coachA">
                    { unanswered !== null ?
                        <div className={styles.container}>
                            <div className={styles.mainLabel}>{Math.floor(unanswered)}%</div>
                            <div className={styles.subLabel}>Messages With No Reply</div>
                        </div>
                        :
                        <div className={styles.container}>
                            <GraphPlaceholder/>
                        </div>
                    }
                </BoxContainer>
            </div>

            <BoxContainer className={styles.schedulingGraph} data-testID="scheduling-coachA">
                <div className={styles.heading2}>Scheduling</div>
                <SchedulingGraph data={scheduleData}/>
            </BoxContainer>

            <BoxContainer className={styles.schedulingGraph} data-testID="calendars-coachA">
                <div className={styles.heading2}>Time Spent On Calls - {scheduleTotal ? `${Math.floor(scheduleTotal / 60) + 'h ' + scheduleTotal % 60}min` : ''}</div>
                <Calendars calendars={calendars}/>
            </BoxContainer>
        </div>
    )
}

export default CoachAnalytics;