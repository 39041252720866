import React from 'react';
// styles
import styles from "./SkeletonText.module.css";

const SkeletonText = ({ width, height }) => {
  return (
    <div
      className={styles.skeletonText}
      style={{ width: width || '100%', height: height || '1em' }}
    ></div>
  );
};

export default SkeletonText;
