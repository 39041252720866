import React from 'react';

// styles
import styles from './victoryAverages.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";
import {BoxContainer} from "../../../containers/boxContainer/boxContainer";

const VictoryAverages = props => {
    const {data} = props;

    if (!data) {
        return (
            <BoxContainer className={styles.container}>
                <GraphPlaceholder/>
            </BoxContainer>
        )
    }
    
    let total = data.distribution.badge_victories.reduce((current, prev) => {
        return current + prev.victory_count
    }, 0);
    total = total + data.distribution.seven_day;
    return (
        <BoxContainer className={styles.container} data-testID="victoryAverages">
            <h4 className="m:0 margin-bottom-15">Victory Averages</h4>
            <div className={styles.statsContainer}>
                <div className={styles.stat}><strong>Profile Views:</strong>&nbsp; {data.profile}</div>
                <div className={styles.stat}><strong>Time between victories:</strong>&nbsp; {data.timespan}</div>
            </div>

            <div><strong>Victory Distribution -</strong> {data.distribution.active_member_count} Coached/Not Coached Members</div>
            <div>
                <div className={`${styles.topRow} ${styles.row}`}>
                    <div className={styles.victory}>Victory</div>
                    <div className={styles.info}>Members</div>
                    <div className={styles.info}>% of Members</div>
                </div>
                {data.distribution.badge_victories.map(victory => (
                    <div className={styles.row}>
                        <div className={styles.victory}>{victory.level_name}</div>
                        <div className={styles.info}>{victory.victory_count}</div>
                        <div className={styles.info}>{Math.round((victory.victory_count/data.distribution.active_member_count)*100)}</div>
                    </div>
                ))}
                <div className={styles.row}>
                    <div className={styles.victory}>7 Day Streak</div>
                    <div className={styles.info}>{data.distribution.seven_day}</div>
                    <div className={styles.info}>{Math.round((data.distribution.seven_day/data.distribution.active_member_count)*100)}</div>
                </div>
                <hr/>
                <div className={styles.row}>
                    <div className={styles.victory}>Total</div>
                    <div className={styles.info}>{total}</div>
                    <div className={styles.info}>{Math.round((total/data.distribution.active_member_count)*100)}%</div>
                </div>
            </div>
        </BoxContainer>
    )
}

export default VictoryAverages;