export const STATUSES = {
    "onboarding_terms_of_service": 1,
    "onboarding_assessment": 2,
    "onboarding_coach_consult_schedule": 3,
    "onboarding_coach_consult_pending": 4,
    "active_coached": 5,
    "active_not_coached": 6,
    "opted_out": 7,
    "closed": 8,
    "deleted": 9,
    "expired_subscription": 10,
};

export const ORG_STATUSES = {
    ACTIVE: 6,
    INACTIVE: 7,
}