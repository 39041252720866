import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Import stripeApiMap
import { stripeApiMap } from "../../../store/actions/stripeActions";

// Other imports...
import ClientHeader from "../../clientHeader/clientHeader";
import ClientSubHeader from "../clientDetail/ClientSubHeader";
import ClientDisplay from "../ClientDisplay/ClientDisplay";
import axios from "axios";
import LinkList from "../../links/linkList/linkList";
import HamburgerMenu from "./HamburgerMenu";
import { ReactComponent as Profile } from "../../../images/icons/Menu_More_Profile.svg";
import {
  isBeforeDate,
  parseDateString,
} from "../clientManageSubscriptions/utils/dateUtils";

class ProfileNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 993,
      wellnessScore: "",
      tiles: [],
      activeTab: "Plan Details",
      tabs: ["Plan Details", "Payment History", "Update Card"],
      showPaymentInfo: false,
      showLockScreen: null,
      trialExpirationDate: null,
      trialLength: null,
    };
    // Bind handlers if not using arrow functions
    this.handleResize = this.handleResize.bind(this);
    this.setTabs = this.setTabs.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    axios
      .get("/score/wellness-score")
      .then((res) => {
        this.setState({
          wellnessScore: res.data.results.score,
        });
      })
      .catch((err) => console.log("ERROR: ", err));

    axios
      .get("/tiles/client")
      .then((res) => {
        this.setState({ tiles: res.data.result });
      })
      .catch((err) => console.log("ERROR: ", err));

    // Call the getUserTrialInfo function here
    this.getUserTrialInfo();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getUserTrialInfo = async () => {
    try {
      const { stripeApiMap } = this.props;
      const { getUserTrialInformation } = await stripeApiMap();
      const response = await getUserTrialInformation();
      const trialExpiresString = response.result.info.trial_expires;
      const trialActive = response.result.trial_active;
      const trialLength = response.result.info.trial_length;
      if (trialExpiresString && trialActive) {
        const trialExpiresDate = parseDateString(
          trialExpiresString,
          "MMMM d, yyyy"
        );
        if (isNaN(trialExpiresDate)) {
          return;
        }
        const today = new Date();
        if (isBeforeDate(trialExpiresDate, today)) {
          // Trial has expired
          this.setState({
            showLockScreen: false,
            trialExpirationDate: trialExpiresString,
          });
        } else {
          // Trial is still active
          this.setState({
            // showPaymentInfo: false,
            tabs: ["Plan Details"],
            showLockScreen: true,
            trialExpirationDate: trialExpiresString,
            trialLength: trialLength,
          });
        }
      } else {
        // No trial info
        this.setState({
          showLockScreen: false,
          trialExpirationDate: trialExpiresString,
        });
      }
    } catch (error) {
      console.error("Unable to get user trial info", error);
    }
  };

  handleResize = () => {
    const isMobile = window.innerWidth <= 993;
    if (this.state.isMobile !== isMobile) {
      this.setState({ isMobile });
    }
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  setTabs = (newTabs) => {
    this.setState({ tabs: newTabs });
  };

  handleManageSubscriptionsClick = () => {
    this.props.history.push(`${this.props.match.url}/managesubscriptions`);
    this.setActiveTab("Plan Details");
  };

  handleSubLinkClick = (tab) => {
    this.props.history.push(`${this.props.match.url}/managesubscriptions`);
    this.setActiveTab(tab);
  };

  render() {
    const { match, user } = this.props;
    const {
      activeTab,
      isMobile,
      tabs,
      trialExpirationDate,
      showLockScreen,
      trialLength,
    } = this.state;
    // Inside render(), right before defining "links", determine if it's a single-tab scenario:
    const isSingleTab =
      this.state.tabs.length === 1 && this.state.tabs[0] === "Plan Details";

    const links = [
      { href: `${match.url}`, label: "Account", icon: <Profile /> },
      { href: `${match.url}/details`, label: "Details" },
      { href: `${match.url}/notifications`, label: "Notifications" },
      { href: `${match.url}/gym`, label: "Gym" },
      { href: `${match.url}/equipment`, label: "Equipment" },
      { href: `${match.url}/settings`, label: "Game Settings" },
      ...(user.organization && user.organization.sales_channel === "retail"
        ? [
            isMobile
              ? // MOBILE LINKS
                isSingleTab
                ? // If there's only one tab "Plan Details", go directly
                  {
                    href: `${match.url}/managesubscriptions`,
                    label: "Manage Subscriptions",
                    onClick: this.handleManageSubscriptionsClick,
                  }
                : // Else, render subLinks
                  {
                    label: "Manage Subscriptions",
                    navigateToSubmenu: true,
                    subLinks: this.state.tabs.map((tab) => ({
                      label: tab,
                      onClick: () => this.handleSubLinkClick(tab),
                    })),
                  }
              : // DESKTOP LINKS
                {
                  href: `${match.url}/managesubscriptions`,
                  label: "Manage Subscriptions",
                  onClick: this.handleManageSubscriptionsClick,
                },
          ]
        : []),
    ];

    return (
      <div className="client-container">
        <ClientHeader backUrl={`/member/clientDash/${user.username}`} />
        <ClientSubHeader />
        <div className="container client-dash margin-top-3x">
          <div className="row">
            <div className="col-xs-12 col-md-3">
              <div className="margin-bottom-med">
                {isMobile ? (
                  <HamburgerMenu key="menu" links={links} />
                ) : (
                  <LinkList
                    key="menu"
                    className="profileNav-links tour-profile-dropdown"
                    links={links}
                  />
                )}
              </div>
            </div>
            <div className="col-xs-12 col-md-9">
              <ClientDisplay
                match={this.props.match}
                username={this.props.match.params.username}
                activeTab={activeTab}
                setActiveTab={this.setActiveTab}
                tabs={tabs}
                setTabs={this.setTabs}
                showLockScreen={showLockScreen}
                trialExpirationDate={trialExpirationDate}
                trialLength={trialLength}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

// Connect stripeApiMap to the component
export default withRouter(
  connect(mapStateToProps, { stripeApiMap })(ProfileNav)
);
