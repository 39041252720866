import React, { useEffect, useState } from "react";
import { CiSquareAlert } from "react-icons/ci";
// redux
import { connect } from "react-redux";
import { stripeApiMap } from "../../../store/actions/stripeActions";

// stripe components
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// styles
import styles from "./ClientManageSubscription.module.css";

// local component
import { BoxContainer } from "../../containers/boxContainer/boxContainer";
import Loading from "../../Loading/Loading";

// screens
import UpdatePaymentCard from "./screens/updatePaymentCard/UpdatePaymentCard";
import PaymentHistory from "./screens/paymentHistory/PaymentHistory";
import ClientPlanOptionForm from "./screens/paymentDetails/ClientPlanOptionForm";
import LockScreen from "./screens/lockScreen/LockScreen";
import CheckoutPricingForm from "./screens/paymentDetails/CheckoutPricingForm";
import ClientMemberShipInformation from "./screens/planDetails/ClientMemberShipInformation";


let stripePromise = loadStripe("pk_test_51Q5sSfPmAh8yxHvyUuxrxWoLxYfNqMHPgptfStMRmqSIAhJJP5kXpC9e5AmjxiKZZp9NuwaAKtKYWPOCrjXlQt5r00sHYrpDFs");
if (window.location.hostname === 'habit.treowellness.com' || window.location.hostname === 'staging.treowellness.com')
{
  stripePromise = loadStripe("pk_live_51Q3h5aBsNqkeqvG8eYrQdUhabLySwov08LONUsffVw8vZUcIlcjNTajS5UPfBYPy4iTJ8m9vptfKpmjSYZtBAIjI00uRUKVbYd");
} 

function ClientManageSubscription(props) {
  // props from ProfileNav.jsx file
  const {
    activeTab: propsActiveTab,
    setActiveTab: propsSetActiveTab,
    tabs: propsTabs,
    showLockScreen,
    trialExpirationDate,
    trialLength,
    hideDesktopTabs,
    setTabs,
  } = props;

  // actions
  const { user, stripeApiMap } = props;

  const [localActiveTab, setLocalActiveTab] = useState("Plan Details");
  const defaultTabs = ["Plan Details", "Payment History", "Update Card"];
  const tabs = propsTabs || defaultTabs;

  // Determine which tab state functions to use
  const activeTab =
    propsActiveTab !== undefined ? propsActiveTab : localActiveTab;
  const setActiveTab =
    propsSetActiveTab !== undefined ? propsSetActiveTab : setLocalActiveTab;

  // local states
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [clientSecretKey, setClientSecretKey] = useState("");
  const [stripePlanInformation, setStripePlanInformation] = useState([]);
  const [loading, setLoading] = useState(true);

  // Handlers
  const handleManageSubscription = async () => {
    try {
      const { postCheckoutSession } = stripeApiMap();
      // show monthley subscription option form
      setShowPaymentInfo(true);
      //submits price id from stripe to backend to genrate secrete key so payment form can show
      const response = await postCheckoutSession(selectedOption.price_id);
      setClientSecretKey(response.result.client_secret);
      // go to stripe payment form once secret key is created
      setActiveTab("Payment Details");
    } catch (error) {
      console.error("Error creating the subscription:", error);
    }
  };

  // Fetch member details
  const fetchStripeMemberDetails = async () => {
    try {
      const { getStripeMemberDetails } = stripeApiMap();
      const response = await getStripeMemberDetails();
      setStripePlanInformation(response.result);
    } catch (error) {
      console.error("Unable to get member details", error);
    }
  };

  useEffect(() => {
    // if their is no subscription then only show Plan Details
    if (stripePlanInformation && stripePlanInformation.subscription === null) {
      if (setTabs !== undefined) {
        setTabs(["Plan Details"]);
      }
    }
  }, [stripePlanInformation]);

  useEffect(() => {
    setLoading(true);
    fetchStripeMemberDetails()
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [clientSecretKey, stripeApiMap]);

  const renderContent = () => {
    // If there's no stripe_id or subscription is null, show ClientPlanOptionForm else show clients subscription plan information
    if (activeTab === "Plan Details") {
      const showPlanOptionForm =
        !user.stripe_id || stripePlanInformation.subscription === null;
      return showPlanOptionForm ? (
        <ClientPlanOptionForm
          handleManageSubscription={handleManageSubscription}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          stripePlanInformation={stripePlanInformation}
          hideDesktopTabs={hideDesktopTabs}
        />
      ) : (
        <ClientMemberShipInformation
          setActiveTab={setActiveTab}
          stripePlanInformation={stripePlanInformation}
          handleManageSubscription={handleManageSubscription}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          showPaymentInfo={showPaymentInfo}
          setShowPaymentInfo={setShowPaymentInfo}
        />
      );
    }

    if (activeTab === "Payment History") {
      return <PaymentHistory />;
    }

    if (activeTab === "Payment Details") {
      return (
        <CheckoutPricingForm
          setActiveTab={setActiveTab}
          selectedOption={selectedOption}
          showPaymentInfo={showPaymentInfo}
          clientSecretKey={clientSecretKey}
        />
      );
    }

    if (activeTab === "Update Card") {
      return (
        <Elements stripe={stripePromise}>
          <UpdatePaymentCard
            stripePlanInformation={stripePlanInformation}
            getStripeMemberDetails={fetchStripeMemberDetails}
          />
        </Elements>
      );
    }

    return null; // Default case if needed
  };

  if (loading) {
    return (
      <BoxContainer className="padding-standard">
        <div className={styles.loader}>
          <Loading />
        </div>
      </BoxContainer>
    );
  }

  // if user still in trial showLockscreen is set to true passed through from ProfileNav component
  if (showLockScreen) {
    return (
      <BoxContainer className="padding-standard">
        <LockScreen date={trialExpirationDate} trialLength={trialLength} />
      </BoxContainer>
    );
  }

  return (
    <>
      {!showLockScreen && (
        <>
          <div className={!hideDesktopTabs ? styles.tabBackground : undefined}>
            {!hideDesktopTabs &&
              tabs.map((tab) => {
                const isPaymentTab =
                  tab === "Payment History" || tab === "Payment Details";
                const lacksStripeId = !user.stripe_id;
                const noClientSecret =
                  tab === "Payment Details" && !clientSecretKey;
                const isDisabled =
                  (isPaymentTab && lacksStripeId) || noClientSecret;
                  let className = styles.inactiveTab;
                  if (activeTab === tab) {
                    className = styles.activeTab;
                  } else if (isDisabled) {
                    className = styles.disabledTab;
                  }
  
                return (
                  <span
                    key={tab}
                    className={className}
                    onClick={() => {
                      if (!isDisabled) setActiveTab(tab);
                    }}
                  >
                    {tab}
                  </span>
                );
              })}
          </div>

          <BoxContainer className="padding-standard">
            {hideDesktopTabs && (
              <p className={styles.alertParagraph}>
                <CiSquareAlert className={styles.alertIcon} />
                <span>
                  We’re excited to have you back! Your membership needs
                  an update—renew now to unlock our paid features.
                </span>
              </p>
            )}
            {renderContent()}
          </BoxContainer>
        </>
      )}
    </>
  );
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, { stripeApiMap })(
  ClientManageSubscription
);
