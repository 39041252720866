import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';

// components
import ClientHeader from "../clientHeader/clientHeader";

// styles
import styles from './UserProgress.module.css';

const UserProgress = ({ user, dailyScore, history }) => {
    const [wellnessScore, setWellnessScore] = useState(null);

    useEffect(() => {
        axios.get('/score/wellness-score')
            .then(res=>setWellnessScore(res.data.results.score))
            .catch();
    }, []);

    let scoreClass = 'noScore';
    if (wellnessScore >= 0 && wellnessScore < 50) {
        scoreClass = 'low';
    } else if (wellnessScore >= 50 && wellnessScore < 75) {
        scoreClass = 'average';
    } else if (wellnessScore >= 75 && wellnessScore < 90) {
        scoreClass = 'high';
    } else if (wellnessScore >= 90) {
        scoreClass = 'highest';
    }

    const getProgressWidth = (current, total) => {
        return Math.min((current / total) * 100, 100);
    };

    return (
        <div>
            <div className={`wellness-bg header-only ${scoreClass}`}>
                <div className="client-container">
                    <ClientHeader type='transparent' backUrl={`/member/clientDash/${user.username}`}/>
                </div>
            </div>
            
            <div className={styles.progressContainer}>
                <h3 className={styles.pageTitle}>My Progress</h3>
                <section className={styles.monthlyActivity}>
                    <h4>Monthly Activity</h4>
                    <div className={styles.activityIcons}>
                        <div className={styles.iconItem}>
                            <img src="https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Locked200x200.png" alt="Badge"/>
                            <span>Badge</span>
                        </div>
                        <div className={styles.iconItem}>
                            <img src="path-to-range-icon" alt="Range"/>
                            <span>Range</span>
                        </div>
                        <div className={styles.iconItem}>
                            <i className="fas fa-user"></i>
                            <span>Health Goal</span>
                        </div>
                        <div className={styles.iconItem}>
                            <i className="fas fa-trophy"></i>
                            <span>{user.lifetime_score || '000,000'}</span>
                        </div>
                    </div>
                </section>

                <section className={styles.streakSection}>
                    <div className={styles.streakBar}>
                        <div className={styles.streakHeader}>
                            <i className="fas fa-fire"></i>
                            <span>7-Day Login Streak</span>
                            <strong>{user.current_streak}/7</strong>
                        </div>
                        <div className={styles.progressBar}>
                            <div 
                                className={styles.progressInner} 
                                style={{width: `${getProgressWidth(user.current_streak, 7)}%`}}
                            />
                        </div>
                    </div>
                </section>

                <section className={styles.statsGrid}>
                    <div className={styles.statItem}>
                        <strong>465</strong>
                        <span>Bonus Points Earned</span>
                        <span>This Month</span>
                    </div>
                    <div className={styles.statItem}>
                        <strong>75</strong>
                        <span>Average Daily</span>
                        <span>Wellness Score</span>
                    </div>
                    <div className={styles.statItem}>
                        <strong>35</strong>
                        <span>Days</span>
                    </div>
                </section>

                <section className={styles.habitGrid}>
                    <div className={styles.habitItem}>
                        <i className="fas fa-brain"></i>
                        <span>Healthy Learning</span>
                        <strong>{user.learning_score || '000,000'}</strong>
                    </div>
                    <div className={styles.habitItem}>
                        <i className="fas fa-clipboard-list"></i>
                        <span>Daily Logging</span>
                        <strong>{user.logging_score || '000,000'}</strong>
                    </div>
                    <div className={styles.habitItem}>
                        <i className="fas fa-heart"></i>
                        <span>Healthy Living</span>
                        <strong>{user.living_score || '000,000'}</strong>
                    </div>
                </section>

                <section className={styles.healthStats}>
                    <div className={styles.healthItem}>
                        <i className="fas fa-bed"></i>
                        <span>Sleep</span>
                        <strong>8 hours</strong>
                        <span>Monthly Average</span>
                    </div>
                    <div className={styles.healthItem}>
                        <i className="fas fa-drumstick-bite"></i>
                        <span>Protein</span>
                        <strong>6 servings</strong>
                        <span>Monthly Average</span>
                    </div>
                </section>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        dailyScore: state.dailyScore
    };
}

export default withRouter(connect(mapStateToProps)(UserProgress));