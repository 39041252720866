import React, {useState, useEffect} from 'react';
import subDays from "date-fns/subDays";
import startOfToday from "date-fns/startOfToday";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

// styles
import styles from './communityAnalytics.module.css';

// actions
import {
    fetchAnalyticsVictories, fetchReactionAverages, fetchVictoryAverages,
} from "../../../store/actions/analyticsActions";

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import VictoryFeed from "./victoryFeed/victoryFeed";
import VictoryAverages from "./victoryAverages/victoryAverages";
import ReactionAverages from "./reactionAverages/reactionAverages";
import {DangerAlert} from "../../alerts/alerts";

const CommunityAnalytics = props => {
    // props
    const {filters} = props;
    // local
    const [date, setDate] = useState({
        start: subDays(startOfToday(), 30),
        end: new Date()
    });
    const [victories, setVictories] = useState(null);
    const [victoryAverages, setVictoryAverages] = useState(null);
    const [reactionAverages, setReactionAverages] = useState(null);
    const [victoryEnd, setVictoryEnd] = useState(false);
    const [query, setQuery] = useState({
        start: date.start,
        end: date.end,
        organizationId: filters.organization,
        statusId: filters.status,
        gender: filters.gender,
        ageGroup: filters.ageGroup,
        coach: filters.coach,
    });

    // on first render
    useEffect(() => {
        runQuery();
    }, [query]);

    useDidMountEffect(() => {
        const newQuery = {
            start: date.start,
            end: date.end,
            organizationId: filters.organization,
            statusId: filters.status,
            gender: filters.gender,
            ageGroup: filters.ageGroup,
            coach: filters.coach,
            rank: filters.rank,
        };
        setQuery(newQuery);
    }, [date, filters]);


    // functions
    function runQuery() {
        loadVictoryFeed();
        loadVictoryAverages();
        loadReactionAverages();
    }

    async function loadVictoryFeed(victoryId) {
        try {
            const res = await fetchAnalyticsVictories(query, victoryId);
            if (victoryId && victories) {
                setVictories([...victories, ...res.victories]);
            } else {
                setVictories(res.victories);
            }
            if (!res.more) setVictoryEnd(true);
            else setVictoryEnd(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function loadReactionAverages() {
        try {
            const res = await fetchReactionAverages(query);
            setReactionAverages(res);
        } catch (e) {
            console.log(e);
        }
    }

    async function loadVictoryAverages() {
        try {
            const res = await fetchVictoryAverages(query);
            setVictoryAverages(res);
        } catch (e) {
            console.log(e);
        }
    }

    function handleDate(e, range) {
        const val = e.target.value;
        let parsedDate = parse(val, 'yyyy-MM-dd', new Date());
        if (range === 'start') {
            parsedDate = startOfDay(parsedDate);
            return setDate({...date, start: parsedDate});
        } else {
            parsedDate = endOfDay(parsedDate);
            return setDate({...date, end: parsedDate});
        }
    }

    return (
        <div>
            <h3 className={styles.heading}> Community Analytics</h3>
            <form className={styles.filter}>
                <div>
                    <label>Choose Date Range</label>
                    <div className={`form-group ${styles.dateRange}`} data-cy="date-range-community">
                        <input
                            max={format(subDays(date.end, 1), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.start, 'yyyy-MM-dd', {})}
                            onChange={e => handleDate(e, 'start')}
                            onKeyDown={e => e.preventDefault()}
                        />
                        <span className='p-r:1 p-l:1'>to</span>
                        <input
                            min={format(addDays(date.start, 1), 'yyyy-MM-dd')}
                            max={format(new Date(), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.end, 'yyyy-MM-dd')}
                            onChange={e => handleDate(e, 'end')}
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>

                </div>
            </form>
            <div>
                <DangerAlert>Demo member info is not included.</DangerAlert>
            </div>
            <div className={styles.container1} >
                <div className={styles.victories} data-testID="community-analytics">
                    <BoxContainer className={styles.victoriesContainer} data-testID="victory-community">
                        <h4 className='m:0 margin-bottom-15'>Victories</h4>
                        <VictoryFeed victories={victories} loadMore={loadVictoryFeed} ended={victoryEnd} data-cy="victory-feed-community"/>
                    </BoxContainer>
                </div>
                <div className={styles.data} data-testID="community-analytics-data">    
                    <div className='margin-bottom-25' data-testID="va-community">
                        <VictoryAverages data={victoryAverages}/>
                    </div>
                    <div data-testID="ra-victory">
                        <ReactionAverages data={reactionAverages} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommunityAnalytics;