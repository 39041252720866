import React, { useEffect, useState } from "react";
import { RxCopy } from "react-icons/rx";
import { LuCheckCircle } from "react-icons/lu";
import { FiRefreshCw } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import styles from "./PaymentHistory.module.css";

import { stripeApiMap } from "../../../../../store/actions/stripeActions";
import { connect } from "react-redux";
import Pagination from "../../components/pagination/Pagination";
import SkeletonText from "../../components/Loading/SkeletonText";

// Import the InvoiceDetails component
import InvoiceDetails from "./InvoiceDetails";

const PaymentHistory = (props) => {
  const { stripeApiMap } = props;
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [lastIdMap, setLastIdMap] = useState({});

  // State to handle selected invoice
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getInvoiceList = async (page) => {
    setLoading(true);
    const { getUserInvoices } = await stripeApiMap();

    let last_id = null;
    if (page > 1) {
      last_id = lastIdMap[page - 1];
    }

    const response = await getUserInvoices(limit, last_id);
    setPaymentHistory(response.result.invoices);

    // Store last_id for next page
    if (response.result.invoices.length > 0) {
      setLastIdMap((prev) => ({
        ...prev,
        [page]:
          response.result.invoices[response.result.invoices.length - 1].id,
      }));
    }

    // Update totalPages based on has_more flag
    if (response.result.has_more) {
      setTotalPages(page + 1);
    } else {
      setTotalPages(page);
    }

    setLoading(false);
  };

  useEffect(() => {
    getInvoiceList(currentPage);
  }, [currentPage, limit]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied: ${text}`);
  };

  const renderStatusIcon = (status) => {
    if (status === "paid") {
      return <LuCheckCircle color={"#4bbc4e"} size={21} />;
    } else if (status === "pending" || status === "open") {
      return <FiRefreshCw color={"#EEBF35"} size={21} />;
    } else if (status === "failed") {
      return <IoCloseCircleOutline color={"#ED4437"} size={21} />;
    } else {
      return null;
    }
  };

  const handleInvoiceSelect = (invoice) => {
    // Set selected invoice data, which will render the InvoiceDetails component
    setSelectedInvoice(invoice);
  };

  // Function to go back to the payment history
  const handleBackToHistory = () => {
    setSelectedInvoice(null);
  };

  // Function to render skeleton rows
  const renderSkeletonRows = () => {
    const skeletonRows = [];
    for (let i = 0; i < limit; i++) {
      skeletonRows.push(
        <tr key={i}>
          <td>
            <div>
              <SkeletonText width="60%" height="1em" />
            </div>
            <div style={{ marginTop: "0.5em" }}>
              <SkeletonText width="40%" height="1em" />
            </div>
          </td>
          <td>
            <SkeletonText width="50%" height="1em" />
          </td>
          <td></td>
          <td className={styles.alignRight}>
            <div className={styles.statusContainer}>
              <span className={styles.statusIcon}>
                <SkeletonText width="21px" height="21px" />
              </span>
              <span>
                <SkeletonText width="21px" height="21px" />
              </span>
              <span>
                <SkeletonText width="21px" height="21px" />
              </span>
            </div>
          </td>
        </tr>
      );
    }
    return skeletonRows;
  };

  if (paymentHistory !== null) {
    if (paymentHistory.length <= 0) {
      return (
        <div className={styles.paymentHistory}>
          <p> There are no invoices to display at this time</p>
        </div>
      );
    }
  }

  // Conditionally render PaymentHistory or InvoiceDetails
  if (selectedInvoice) {
    return (
      <InvoiceDetails invoice={selectedInvoice} onBack={handleBackToHistory} />
    );
  }

  return (
    <div className={styles.paymentHistory}>
      <h3>Payment History</h3>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            <th className={styles.tableHeaderCell}>Invoice</th>
            <th></th>
            <th></th>
            <th style={{ textAlign: "right" }}>
              <span className={styles.statusSpan}>
                Status <MdKeyboardArrowDown />
              </span>
              <span className={styles.dateSpan}>
                {" "}
                By Date <MdKeyboardArrowDown />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {loading
            ? renderSkeletonRows()
            : paymentHistory.map((payment) => (
                <tr key={payment.id}>
                  <td>
                    <div className={styles.paymentDate}>{payment.date}</div>
                    <div className={styles.paymentNumber}>
                      {payment.number}
                      <RxCopy
                        className={styles.copyIcon}
                        onClick={() => copyToClipboard(payment.number)}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <p className={styles.paymentAmount}>
                        {`$${payment.total.toFixed(2)}`}
                      </p>
                    </div>
                  </td>
                  <td></td>
                  <td className={styles.alignRight}>
                    <div className={styles.statusContainer}>
                      <span className={styles.statusIcon}>
                        {renderStatusIcon(payment.status)}
                      </span>
                      <span>
                        <a
                          href={payment.invoice_pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FiDownload size={21} color={"lightgrey"} />
                        </a>
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleInvoiceSelect(payment)}
                      >
                        <MdKeyboardArrowRight size={21} />
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className={styles.pagination}>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

const actions = {
  stripeApiMap,
};

export default connect(null, actions)(PaymentHistory);
