// additionalLinks.js
export const links = [
    {
      title: "Refund Policy",
      content: `
        <div>
          <p><strong>Users can only request a refund if:</strong></p>
          <ol>
            <li>
              If the payment was unauthorized (not made by you) and is within
              120 days of the transaction.
            </li>
          </ol>
          <p>
          Refund requests will be processed
            within 1-4 business days from the time of submission.
          </p>
          <p><strong>Request a refund</strong></p>
        </div>
      `,
      id: 'refund-policy-id',
    },
  ];
  