import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import {Glyphicon} from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import Giphy from "../../Giphy/giphyContainer";
import { sanitize } from 'dompurify';

// components
import ContentDisplayContainer from '../../contentDisplay/MainContainer';
import Message from '../../ErrorMessage/Error';
import GenericTextArea from '../../genericWYSIWYG/genericWYSIWYG';

//props
// user > object
// btn > string
//cancel > function (closes new message window)
//post_id > int ? marks message as reply : new message/thread
//recipients > array of objects ? sets recipents for a reply : null on new message
//refreshThreads > function
//refreshThread > function

const NewMessage = props => {
    const [state, updateState] = useState({
        modal_show:false,

        //message contents
        title : props.title || '',
        text : '',
        recipients : [],
        content : [],
        post_id : props.post_id, //default undefined
        recipient_list:[],

        // catch multiple submits
        hasSent:null,

        //message
        message:null,
        message_type:null,
        grouped:true,
        gif: null,

    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    useEffect(() => {
        let allRecipients = [];
        if(props.recipients){
            allRecipients = props.recipients.filter(recipient => !!recipient);
            if(allRecipients.length === 0) setState({message:'User not found, consider archiving this thread', message_type:'danger'});
            let recipients = [];
            //set recipients for a reply message
            if(allRecipients){
                allRecipients.map(item=>{
                    recipients.push(item.username);
                });
                setState({recipients});
            }
        }
        else{
            //get recipient list
            axios.get('/posts/users/')
                .then(res=>{
                    setState({recipient_list:res.data.result})
                })
        }
    }, []);

    const modalHandleClose = () =>{
        setState({modal_show:false});
    };

    //add attachments to message
    const attachContent = (item) =>{
        let content = state.content;
        setState({content : [...content, item]})
    };

    //remove attachments from message
    const removeContent = (id) =>{
        let content = state.content.filter(item=>item.id !== id);
        setState({content});
    };

    const renderAttachments=()=>{
        return state.content.map(item=>{
            return (<h5 className='reply-media-text'>{item.title}<Glyphicon onClick={()=>removeContent(item.id)} glyph='glyphicon glyphicon-trash'> </Glyphicon></h5>);
        });
    };

    //render title if new message
    const renderTitle=()=>{
        if(!state.post_id){
            return (
                <div>
                    <label>Topic Title</label>
                    <input type="text" value={state.title} onChange={e=>setState({title:e.target.value})}/>
                </div>
            )
        }

    };


    const handleSubmit = (e) =>{
        e.preventDefault();
        // disable multiple submits
        setState({ hasSent: true});

        //gather info to be submitted
        let submission = {
            title : state.title,
            text : state.text,
            grouped : state.grouped,
        };

        //check if recipients if object array
        if(state.recipients.length < 1){
            setState({message:'You must have 1 recipient', message_type:'danger'});
            // enable submit btn again
            setState({ hasSent: false});
            return;
        }else{
            if(typeof state.recipients[0] === 'object'){
                submission.recipients = state.recipients.map(item=>item.value);
            }else{
                submission.recipients = state.recipients;
            }
        }

        // check if title was entered
        if (state.title.length < 1 ) {
            // validation message, type, and enable submit btn again
            setState({message:'You must have a message title', message_type:'danger', hasSent: false});
            return;
        } else {
            submission.title = state.title;
        }


        if(state.post_id) submission.post_id = state.post_id;
        if(state.content.length > 0) submission.content = state.content.map(item=>item.id);

        let url = `/posts/new-message`;

        //send to db
        axios.post(url, submission)
            .then((res)=>{
                props.cancel();
                props.refreshThread(res.data.thread.id);

            })
            .catch(e=>{
                // validation message, type, and enable submit btn again
                setState({message:e.response.data.error, message_type:'danger', hasSent: false})
            });
    };

    const renderRecipients=()=>{
        if(!state.post_id){
            return (
                <div>
                    <div className="row no-margin">
                        <input className="sprint8-check" id="checkBox" type="checkbox" checked={state.group} onChange={(e)=>setState({grouped:!state.grouped})}></input>
                        <span><label>Send this as individual messages</label></span>
                    </div>
                    <label>Recipients:</label>
                    <Select
                        value={state.recipients}
                        isMulti={true}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={state.recipient_list}
                        onChange = {e=>{
                            setState({recipients : e})
                        }}
                    />
                </div>
            )
        }
    };

    const changeText=(text)=>{
        setState({text: sanitize(text)});
    };

    const gifChange = (gif) => {
        const image = `<img style="width:250px;height: auto;" src=${gif.images.downsized_medium.url} alt="gif"/>`;
        setState({ gif: image });
    };

    return(
        <form onSubmit={e=>handleSubmit(e)}>
            <div className='inbox-message inbox-new'>
                <Message message={state.message} type={state.message_type}/>
                <div>
                    {renderRecipients()}
                    {renderTitle()}
                    <label>Message</label>
                    <div data-testID='gif-container'>
                        <Giphy callback={gifChange}/>
                    </div>
                    <GenericTextArea
                        className='_text'
                        value={state.text}
                        plugins ='link paste lists emoticons'
                        toolbar ='undo redo | bold italic | numlist bullist | alignleft aligncenter alignright'
                        onChangeHandler={changeText}
                        addContent={state.gif}
                    />
                    <div className="reply-media">
                        <h4 className="reply-media-text" onClick={()=>setState({modal_show : !state.modal_show})}>Click to Attach Recommended Content or Media</h4>
                        {renderAttachments()}
                    </div>
                    <button disabled={state.hasSent} className='btn' onClick={e=>state.hasSent ? null : e=>handleSubmit(e)}>
                        {props.btn || 'Send'}
                    </button>
                    <button className='btn' onClick={props.cancel}>Cancel</button>
                </div>
            </div>
            <Modal show={state.modal_show} onHide={modalHandleClose}>
                <Modal.Body>
                    <div className="client-container">
                        <ContentDisplayContainer
                            loadLatest={true}
                            inbox={true}
                            attachContent={attachContent}
                            modal={true}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </form>
    )
}

export default withRouter(NewMessage);