import React from 'react';
import styles from './LockScreen.module.css';

const LockScreen = ({date = "January 3, 2025", trialLength = 1}) => {
  return (
    <div className={styles.rankContainer}>
      <img
        className={styles.rankLocked}
        src={
          'https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Locked200x200.png'
        }
        alt="rank"
      />
      <div>
      <p className={styles.rankText}>
        Your {trialLength} {trialLength === 1 ? 'Month' : 'Months'} Trial Expires on
      </p>
      <p className={styles.rankText}> <strong>{date}</strong> </p>
      </div>
      <p className={styles.rankText}> You are currently in Treo's Trial Period, When your trial expires, you will be asked for credit card information</p>
      <p className={styles.rankText}>
      <span style={{ textDecoration: "underline", fontWeight: 600 }}>
        You will not be charged
      </span>{" "}
      until you verify payment information!
    </p>
    </div>
  );
};

export default LockScreen;
