import React, { useEffect } from "react";

// redux
import { connect } from "react-redux";
import { stripeApiMap } from "../../../../../store/actions/stripeActions";

// styles
import styles from "./paymentDetails.module.css";

// stripe componets
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";

// local components
import SkeletonText from "../../components/Loading/SkeletonText";
import { ReactComponent as BackArrow } from "../../../../../images/icons/back_arrow_long_tail.svg";

let stripePromise = loadStripe("pk_test_51Q5sSfPmAh8yxHvyUuxrxWoLxYfNqMHPgptfStMRmqSIAhJJP5kXpC9e5AmjxiKZZp9NuwaAKtKYWPOCrjXlQt5r00sHYrpDFs");
if (window.location.hostname === 'habit.treowellness.com' || window.location.hostname === 'staging.treowellness.com')
{
  stripePromise = loadStripe("pk_live_51Q3h5aBsNqkeqvG8eYrQdUhabLySwov08LONUsffVw8vZUcIlcjNTajS5UPfBYPy4iTJ8m9vptfKpmjSYZtBAIjI00uRUKVbYd");
} 
// 
const CheckoutPricingForm = (props) => {
  const {
    selectedOption,
    showPaymentInfo,
    clientSecretKey,
    stripeApiMap,
    setActiveTab,
  } = props;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id");

  const fetchSessionStatus = async (sessionId) => {
    try {
      const { getSessionStatus } = stripeApiMap();
      await getSessionStatus(sessionId);
    } catch (error) {
      console.error("Error fetching session status:", error.message);
    }
  };
  useEffect(() => {
    if (sessionId) {
      fetchSessionStatus(sessionId);
    }
  }, []);
  return (
    <div>
      <div
        className={styles.backIcon}
        onClick={() => setActiveTab("Plan Details")}
      >
        <BackArrow style={{ fill: "Black", width: 40 || "unset" }} />
        <span style={{ marginLeft: "10px", position: "relative", top: "-3px" }}>
          BACK
        </span>
      </div>
      {showPaymentInfo && selectedOption && (
        <div style={{ marginTop: "20px" }}>
          {clientSecretKey ? (
            <>
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{
                  clientSecret: clientSecretKey,
                }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </>
          ) : (
            <div className={styles.skeletonContainer}>
              <SkeletonText width={"70%"} height={"30px"} />
              <SkeletonText width={"30%"} height={"30px"} />
            </div>
          )}
        </div>
      )}
      {!showPaymentInfo && <p>Loading...</p>}
    </div>
  );
};

function mapStateToProps({ user }) {
  return {
    user,
  };
}

const actions = {
  stripeApiMap,
};

export default connect(mapStateToProps, actions)(CheckoutPricingForm);
