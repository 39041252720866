import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux';

// styles
import styles from './teamInbox.module.css';

// actions
import {fetchMessages, fetchThread, markThreadRead, setArchivalStatus} from "../../../store/actions/postsActions";

// components
import Loading from "../../Loading/Loading";
import InboxList from "../inboxList/inboxList";
import ThreadBox from "../threadBox/threadBox";
import NewThreadBox from '../newThreadBox/newThreadBox';
import {BasicButton} from "../../buttons/buttons";
import {ErrorAlert} from "../../alerts/alerts";
import {PERMISSIONS} from "../../../serverVariables/permissions";

const TeamInbox = props => {
    // state
    const {user, singleUser} = props;
    // local
    const [ready, setReady] = useState(false);
    const [error, setError] = useState('');
    const [page, setPage] = useState(0);
    const pages = useRef(0);
    const [threads, setThreads] = useState([]);
    const [singleThread, setSingleThread] = useState(null);
    const [newMessage, setNewMessage] = useState(false);
    const isLoading = useRef(false);
    const [archived, setArchived] = useState(false);
    const [loading, setLoading] = useState(false);
    const [endReached, setEndReached] = useState(false);

    // set view to ready
    useEffect(() => {
        mount();
        return () => cleanup();
    }, []);

    useEffect(() => {
        if (isLoading.current || page < 1) return;
        isLoading.current = true;
        getMessages();
    }, [page]);

    useEffect(() => {
        setPage(1);
        if (page === 1) getMessages();
    }, [archived])

    async function mount() {
        setPage(prevState => prevState +1);
        window.addEventListener('scroll', detectScroll, {passive: true})
        setReady(true);
    }

    async function cleanup() {
        window.removeEventListener('scroll', detectScroll);
    }

    async function getMessages() {
        setLoading(true);
        try{
            const options = {archived};
            if (user.permission_id === PERMISSIONS.ADMIN) {
                options.override = singleUser.user.username;
            } else {
                options.username = singleUser.user.username;
            }
            const messages = await fetchMessages(page, options);
            if (page === 1) {
                setThreads(messages.threads);
            } else { setThreads([...threads, ...messages.threads]); }
            pages.current = parseInt(messages.pages);
            setLoading(false);
            if (page === pages.current){
                setEndReached(true);
            } else { setEndReached(false)}
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error)
            } else {
                setError('Unable to get messages. If this error persists, please contact your systems administrator.')
            }
        }
        finally {
            isLoading.current = false;
        }
    }

    async function selectThread(threadId, isNew=false) {
        setNewMessage(false);
        try {
            fetchThread(threadId)
                .then(result => {
                    if (user.permission_id === PERMISSIONS.COACH) {
                        markThreadRead(threadId, user.username);
                        let updates = threads.map(thread => {
                            if (thread.id === threadId) {
                                return {...thread, unread: "0"}
                            } else {
                                return thread;
                            }
                        });
                        if (isNew) updates = [{...result, unread: "0"}, ...threads];
                        setThreads(updates);
                    }
                    setSingleThread(result);
                })
        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error)
            } else {
                setError('Unable to get thread. If this error persists, please contact your systems administrator.')
            }
            setSingleThread(null);
        }
    }

    function closeThreadPane() {
        setSingleThread(null);
    }

    function detectScroll() {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = document.documentElement;

        if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading.current) {
            setPage(prevState => {
                if (prevState !== pages.current){
                    return prevState + 1
                }
                return prevState;
            });
        }
    }

    function toggleArchived() {
        setArchived(!archived);
    }

    function setArchivedThread(threadId, archive) {
        setArchivalStatus(archive, threadId, user.username)
            .then(() => {
                setSingleThread(null);
                setThreads(threads.filter(thread => {
                    if (thread.id === threadId) {
                        return false
                    } else {
                        return thread;
                    }
                }))
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setError(e.response.data.error)
                } else {
                    setError('Unable to archive message. If this error persists, please contact your systems administrator.')
                }
            })
    }

    if (!ready) return (
        <div className="m-t:2 d:f j-c:c">
            <Loading/>
        </div>
    );
    return (
        <div className="container margin-top-lrg">
            <div className="row">
                <div className={`col-xs-12 ${styles.archiveContainer}`}>
                    {user.permission_id === PERMISSIONS.COACH ?
                        <button className="btn primary-test" data-testid="newMessageBtn" onClick={(e) => setNewMessage(true)}>
                            New Message
                        </button>
                        : null
                    }
                    { archived ?
                        <BasicButton onClick={toggleArchived} data-testID="vMessagesBtn">View Messages</BasicButton>
                        :
                        <BasicButton onClick={toggleArchived} data-testID="vArchivedBtn">View Archived</BasicButton>}
                </div>
            </div>
            <div className="row">
                {/*Error Message*/}
                <div className="col-xs-12">
                    {error ? <ErrorAlert>{error}</ErrorAlert> : null}
                </div>
            </div>
            <div className="row">
                {/*List of Threads*/}
                <div className="col-xs-12">
                    <InboxList
                        list={threads}
                        selectThread={selectThread}
                        active={singleThread}
                        archiveCallback={user.permission_id === PERMISSIONS.COACH ? setArchivedThread : null}/>
                    {loading ?
                        <Loading/>
                        : null
                    }
                    {endReached ?
                        <p className='t-a:c text-info'>You're all caught up</p>
                        : null
                    }
                    <div className={`${styles.threadContainer} ${singleThread ? '' : styles.hide}`}>
                        {singleThread ?
                            <ThreadBox
                                thread={singleThread}
                                user={user}
                                close={closeThreadPane}
                                refreshThread={selectThread}
                                archiveCallback={user.permission_id === PERMISSIONS.COACH ? setArchivedThread : null}
                                disableReply={user.permission_id === PERMISSIONS.ADMIN}/>
                            : null
                        }
                    </div>
                    <div className={`${styles.threadContainer} ${newMessage ? '' : styles.hide}`}>
                        {newMessage ?
                            <NewThreadBox
                                thread={singleThread}
                                user={user}
                                close={() => setNewMessage(false)}
                                newThreadCallback={selectThread}/>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {user: state.user, singleUser: state.singleUser};
}

export default connect(mapStateToProps)(TeamInbox);