import React, { useState } from "react";
// Stripe components
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";

// Styles
import styles from "./UpdatePaymentCard.module.css";

// Redux
import { stripeApiMap } from "../../../../../store/actions/stripeActions";
import { connect } from "react-redux";

// Components
import { PrimaryButton } from "../../../../buttons/buttons";
import CardTypes from "../../components/cardTypes/CardTypes";
import CardDetailsForm from "../../components/cardDetailsForm/CardDetailsForm";

const UpdatePaymentCard = ({
  stripeApiMap,
  stripePlanInformation,
  getStripeMemberDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [postalCode, setPostalCode] = useState(""); 

  const assignNewCard = async (sub_id, pm_id) => {
    try {
      const { assignNewCardToSubscription } = await stripeApiMap();
      await assignNewCardToSubscription(sub_id, pm_id);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const refreshDetails = async () => {
    await getStripeMemberDetails();
  };

  const handleUpdateCard = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements) {
      setMessage("Stripe is not loaded properly.");
      setIsSubmitting(false);
      return;
    }

    const validatePostalCode = (code) => {
      // Simple regex for US ZIP codes
      return /^\d{5}(-\d{4})?$/.test(code);
    };

    if (!validatePostalCode(postalCode)) {
      setMessage("Please enter a valid ZIP code.");
      setIsSubmitting(false);
      return;
    }

    try {
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement) {
        setMessage("Card element not found.");
        setIsSubmitting(false);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          address: {
            postal_code: postalCode, 
          },
        },
      });
      if (error) {
        setMessage(error.message);
        setIsSubmitting(false);
        return;
      }

      await assignNewCard(
        stripePlanInformation.subscription_id,
        paymentMethod.id
      );
      await refreshDetails();
      setMessage("Card updated successfully.");
    } catch (error) {
      console.error("Error updating card:", error);
      setMessage("An error occurred while updating the card.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleUpdateCard} style={{maxWidth: "400px"}}>
      <CardTypes
        selectedCardId={selectedCardId}
        setSelectedCardId={setSelectedCardId}
        cardInformation={stripePlanInformation.card}
      />
  
      <p style={{fontSize: "18px",}}>Replace Card</p>
      <CardDetailsForm postalCode={postalCode} setPostalCode={setPostalCode} />

      {message && <p className={styles.message}>{message}</p>}

      <div className={styles.btnContainer}>
        <PrimaryButton disabled={isSubmitting} type="submit">
          {isSubmitting ? "Updating..." : "Save Changes"}
        </PrimaryButton>
      </div>
    </form>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const actions = { stripeApiMap };

export default connect(mapStateToProps, actions)(UpdatePaymentCard);
