export const VICTORY_TYPES = {
    BADGES: 1,
    STREAK: 2,
    CHECK_IN_8: 3,
    CHECK_IN_15: 4,
    MEALTIME_SPACING: 5,
    EATING_OCCASIONS: 6,
    VEGETABLES_AND_FRUIT: 7,
    PROTEIN_SERVINGS: 8,
    SWEETENED_FOODS: 9,
    SWEETENED_DRINKS: 10,
    STRESS_RELEASING: 11,
    SLEEP: 12,
    PHYSICAL_ACTIVITY: 13,
    SPRINT_INTENSITY: 14,
    STRENGTH_BUILDING: 15,
    RELATED_SKILLS: 16,
    FOUNDATIONS: 17,
    COACH: 18,
    JUST_FOR_YOU: 19,
    RANK: 20,
    VIDEOS_WATCHED_8: 21,
    VIDEOS_WATCHED_15: 22,
    STEPS_100K: 23,
    STEPS_150K: 24,
    STEPS_200K: 25,
    STEPS_300K: 26,
}