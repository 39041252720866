import React from "react";
import { Modal } from "react-bootstrap";
import { PrimaryButton } from "../../../../buttons/buttons";
import styles from "./ManageSubscriptionModal.module.css";
import Loading from "../../../../Loading/Loading";

const ClientManageSubscriptionModal = ({
  show,
  onHide,
  isFinishing,
  handleFinish,
  title,
  message,
  buttonText,
  LogoComponent,
  children,
  className,
  showModalPrimaryButton,
  modalBodyClass,
}) => {
  return (
    <Modal
      show={show}
      className={`${styles.modalContainer} ${className}`}
      centered
      onHide={onHide}
      dialogClassName={styles.modalDialog} 
    >
      <Modal.Body className={`${styles.modalBody} ${modalBodyClass}`}>
        {LogoComponent && (
          <LogoComponent width={180} height={180} className={styles.logo} />
        )}
        <h2 className={styles.modalTitle}>{title}</h2>
        {message && <p className={styles.modalText}>{message}</p>}

        {/* Render custom content */}
        {children}

        {showModalPrimaryButton ? (
          isFinishing ? (
            <Loading />
          ) : (
            <PrimaryButton className={styles.modalButton} onClick={handleFinish}>
              {buttonText}
            </PrimaryButton>
          )
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default ClientManageSubscriptionModal;
