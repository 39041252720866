// ClientDisplay.js
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Account from "../ClientProfile/Account";
import Details from "../ClientProfile/Details";
import Gym from "../ClientProfile/Gym";
import Equipment from "../ClientProfile/Equipment";
import Notifications from "../ClientProfile/notifications";
import PersonalGoal from "../ClientProfile/personalGoal/personalGoal";
import ClientManageSubscriptions from '../clientManageSubscriptions/ClientManageSubscriptions';

const ClientDisplay = ({ match, username, user, activeTab, setActiveTab, tabs, setTabs, showLockScreen , trialExpirationDate, trialLength}) => {
  return (
    <div>
      <Route
        exact
        path={match.url}
        render={(props) => <Account {...props} username={username} />}
      />
      <Route
        path={`${match.url}/details`}
        render={(props) => <Details {...props} username={username} />}
      />
      <Route
        path={`${match.url}/gym`}
        render={(props) => <Gym {...props} username={username} />}
      />
      <Route
        path={`${match.url}/equipment`}
        render={(props) => <Equipment {...props} username={username} />}
      />
      <Route
        path={`${match.url}/notifications`}
        render={(props) => <Notifications {...props} username={username} />}
      />
      <Route
        path={`${match.url}/settings`}
        render={(props) => <PersonalGoal {...props} username={username} />}
      />
      <Route
        path={`${match.url}/managesubscriptions`}
        render={(props) => (
          <ClientManageSubscriptions
            {...props}
            username={username}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
            setTabs={setTabs}
            showLockScreen={showLockScreen} 
            trialExpirationDate= {trialExpirationDate}
            trialLength={trialLength}
          />
        )}
      />
    </div>
  );
};

export default withRouter(ClientDisplay);