import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";

// actions
import {logout} from "../../../store/actions/authActions";

// styles
import styles from './moreNavigation.module.css';

// images
import {ReactComponent as RightArrow} from '../../../images/chevron-right-solid.svg';
import {ReactComponent as ProfileIcon} from '../../../images/icons/Menu_More_Profile.svg';
import {ReactComponent as PathwaysIcon} from '../../../images/icons/Menu_More_Pathways.svg';
import {ReactComponent as CoachingIcon} from '../../../images/icons/Menu_More_Coaching Team.svg';
import {ReactComponent as TeammatesIcon} from '../../../images/icons/Menu_More_Teammates.svg';
import {ReactComponent as AssessmentsIcon} from '../../../images/icons/Menu_More_Assessments.svg';
import {ReactComponent as PoliciesIcon} from '../../../images/icons/Menu_More_Policies.svg';
import {ReactComponent as WellnessIcon} from '../../../images/icons/Menu_More_Wellness_Score.svg';
import {ReactComponent as HelpIcon} from '../../../images/icons/Menu_More_Help_Tutorials.svg';
import {ReactComponent as LogoutIcon} from '../../../images/icons/Menu_More_Logout.svg';

// components
import ClientHeaderMinimal from "../../clientHeaderMinimal/clientHeaderMinimal";
import TutorialModal from "../../tutorialModal/tutorialModal";
import {Modal} from "react-bootstrap";

const MoreNavigation = props => {
    // state
    const {user} = props;
    // props
    const {close, location} = props;
    // actions
    const {logout} = props;
    // local
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        close();
    }, [location]);

    function logOutUser(e) {
        e.preventDefault();
        try {
            logout()
                .then(() => window.location.assign('/'));
        } catch(e) {
            console.log(e);
        }
    }

    function openHelp() {
        close();
        setShowModal(true);
    }

    function closeHelp() {
        setShowModal(false);
    }

    return (
        <div className={styles.container}>
            <ClientHeaderMinimal close={close}/>
            <ul>
                <li>
                    <Link className={styles.row} to={`/member/clientDash/${user.username}/account`} data-cy="more-nav-profile">
                        <ProfileIcon className={styles.icon}/>
                        <span className={styles.text}>Profile</span>
                        <RightArrow className={styles.arrow}/>
                    </Link>
                </li>
                <li>
                    <Link className={styles.row} to={`/member/clientDash/${user.username}/pathways`} data-cy="more-nav-pathway">
                        <PathwaysIcon className={styles.icon}/>
                        <span className={styles.text}>Pathway Reports</span>
                        <RightArrow className={styles.arrow}/>
                    </Link>
                </li>
                <li>
                    <Link className={styles.row} to={`/member/clientDash/${user.username}/coaching-team/coaches`} data-cy="more-nav-coaches">
                        <CoachingIcon className={styles.icon}/>
                        <span className={styles.text}>Coaching Team</span>
                        <RightArrow className={styles.arrow}/>
                    </Link>
                </li>
                <li>
                    <Link className={`${styles.row} tour-teamBtn`} to={`/member/clientDash/${user.username}/teammates`} data-cy="more-nav-teammates">
                        <TeammatesIcon className={styles.icon}/>
                        <span className={styles.text}>Teammates</span>
                        <RightArrow className={styles.arrow}/>
                    </Link>
                </li>
                <li>
                    <Link className={styles.row} to={`/member/clientDash/${user.username}/assessments`} data-cy="more-nav-assessments">
                        <AssessmentsIcon className={styles.icon}/>
                        <span className={styles.text}>Assessments</span>
                        <RightArrow className={styles.arrow}/>
                    </Link>
                </li>
                <li>
                    <Link className={styles.row} to={`/member/clientDash/${user.username}/policies`} data-cy="more-nav-policies" >
                        <PoliciesIcon className={styles.icon}/>
                        <span className={styles.text}>Policies</span>
                        <RightArrow className={styles.arrow}/>
                    </Link>
                </li>
                {/* <li>
                    <Link className={styles.row} to={`/member/score`} data-cy="more-nav-wellness-score">
                        <WellnessIcon className={styles.icon}/>
                        <span className={styles.text}>Wellness Score History</span>
                        <RightArrow className={styles.arrow}/>
                    </Link>
                </li> */}
                <li>
                    <span onClick={openHelp} className={`${styles.row} tour-end`} data-cy="more-nav-help-tutorial">
                        <HelpIcon className={`tour-end ${styles.icon}`}/>
                        <span className={styles.text}>Help & Tutorials</span>
                        <RightArrow className={styles.arrow}/>
                    </span>
                </li>
                {!user.kiosk ?
                    <li>
                    <span onClick={logOutUser} className={styles.row} data-cy="more-nav-logout">
                        <LogoutIcon className={styles.icon}/>
                        <span className={styles.text}>Logout</span>
                        <RightArrow className={styles.arrow}/>
                    </span>
                    </li>
                    : null
                }
            </ul>

            <Modal show={showModal} onHide={closeHelp}>
                <TutorialModal close={closeHelp}/>
            </Modal>
        </div>
    )
}

function mapStateToProps({user}){
    return {user};
}

const actions = {
    logout,
}

export default withRouter(connect(mapStateToProps, actions)(MoreNavigation));