import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

// actions
import { fetchActivePolicies, fetchPolicyVersion } from '../../../store/actions/policyActions';
import { stripeApiMap } from '../../../store/actions/stripeActions';

// styles
import styles from './termsOfService.module.css';
import { BasicButton, PrimaryButton } from '../../buttons/buttons';

// import the links
import { links } from './additionalLinks';

const TermsOfService = (props) => {
  const { loadPolicies, horizontal, additionalLink, stripeApiMap, darkOverlay } = props;

  const [policies, setPolicies] = useState([]);
  const [versions, setVersions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPolicy, setCurrentPolicy] = useState({ title: '' });
  const [currentVersion, setCurrentVersion] = useState({ version_number: '' });
  const [comments, setComments] = useState('');

  // track if component is still mounted
  const isMountedRef = useRef(true);

  // track any setTimeout used in closePolicyModal
  const timeoutRef = useRef(null);

  useEffect(() => {
    async function prepare() {
      try {
        const TOS = await fetchActivePolicies();
        // only proceed if still mounted
        if (!isMountedRef.current) return;

        const versionArray = await Promise.all(
          TOS.map((item) => fetchPolicyVersion(item.active_version_id))
        );
        if (!isMountedRef.current) return;

        let updatedPolicies = TOS.map((item) => ({
          ...item,
          accepted: true,
        }));

        // If additionalLink is true, replace "Scope of Services" with Refund Policy
        if (additionalLink) {
          updatedPolicies = updatedPolicies.filter(
            (policyItem) => policyItem.title.toLowerCase() !== 'scope of service'
          );

          // Append the Refund Policy from links
          updatedPolicies.push({
            id: links[0].id,
            title: links[0].title,
            accepted: true,
            active_version_id: null,
          });
        }

        if (isMountedRef.current) {
          setVersions(_.flatten(versionArray));
          setPolicies(updatedPolicies);
          loadPolicies(TOS);
        }
      } catch (e) {
        console.log(e);
      }
    }

    prepare();

    // cleanup to set isMounted to false when component unmounts
    return () => {
      isMountedRef.current = false;

      // clear any pending timeouts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPolicies, additionalLink]);

  function showPolicyModal(policy, versionId) {
    setCurrentPolicy({ title: policy.title });

    if (policy.id === 'refund-policy-id') {
      // Use the Refund Policy content
      setCurrentVersion({ version_number: '1', text: links[0].content });
    } else {
      const foundVersion = versions.find((v) => v.id === versionId);
      if (foundVersion) {
        setCurrentVersion({
          version_number: foundVersion.version_number,
          text: foundVersion.text,
        });
      }
    }

    setShowModal(true);
  }

  const debouncedShowPolicyModal = _.debounce((policy, versionId) => {
    showPolicyModal(policy, versionId);
  }, 300);
  
  function closePolicyModal() {
    if (!showModal) return; // Prevent closing if already closed
    setShowModal(false);
    // wait 1 second to reset everything; store the timeout ID
    timeoutRef.current = setTimeout(() => {
      if (!isMountedRef.current) return;
      setCurrentPolicy({ title: '' });
      setCurrentVersion({ version_number: '' });
      setComments('');
    }, 1000);
  }

  const handleRefundSubmit = async (e) => {
    e.preventDefault();
    try {
      const { postRequestStripeRefund } = stripeApiMap();
      await postRequestStripeRefund(comments);
      closePolicyModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={horizontal ? styles.horizontal : ''}>
      {policies.map((policy) => (
        <div key={policy.id} className={`TOS-row ${styles.row}`}>
          <label
          onClick={() => debouncedShowPolicyModal(policy, policy.active_version_id)}
          className={styles.policy}
        >
            {policy.title}
          </label>
        </div>
      ))}

         {/* Conditionally render overlay */}
      {showModal && darkOverlay && <div className={styles.overlay}></div>}
      <Modal show={showModal} onHide={closePolicyModal} style={{zIndex: 99999}}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentPolicy.title} <span>(version {currentVersion.version_number})</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentVersion.version_number && (
            <div>
              <div
                className="margin-bottom-2x"
                dangerouslySetInnerHTML={{ __html: currentVersion.text }}
              />
              {currentPolicy.title === 'Refund Policy' && (
                <form onSubmit={handleRefundSubmit}>
                  <div className="margin-bottom-1x">
                    <label htmlFor="comments">Comments</label>
                    <textarea
                      id="comments"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      style={{ width: '100%', minHeight: '100px' }}
                    />
                  </div>
                  <div className={styles.btnContainer}>
                    <PrimaryButton type="submit">Request a refund</PrimaryButton>
                  </div>
                </form>
              )}
              <div className="margin-top-2x">
                <BasicButton onClick={closePolicyModal}>CLOSE</BasicButton>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, { stripeApiMap })(TermsOfService);
