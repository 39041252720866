import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import validate from 'validate.js';
import axios from 'axios';
import moment from 'moment';

// actions
import { getOrganizationCodes, setOrganizationCodePage } from "../../../actions/action_organizations";

// styles
import styles from './organizationCodes.module.css';

// components
import {PrimaryButton, BasicButton, DangerButton} from "../../buttons/buttons";
import Loading from "../../Loading/Loading";
import { Modal, Table} from "react-bootstrap";
import { ErrorAlert } from "../../alerts/alerts";
import { GeneralPagination } from "../../pagination/generalPagination/generalPagination";

validate.extend(validate.validators.datetime, {
    parse: function(value, options) {
        return +moment.utc(value);
    },
    // Input is a unix timestamp
    format: function(value, options) {
        const format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
        return moment(value).format(format);
    }
});


const OrganizationCodes = ({ organization, codes, getOrganizationCodes, setPage }) => {
    const [ loading, setLoading ] = useState(true); // display the loading screen
    const [ singleCode, setSingleCode ] = useState(null); // hold a single code for edit/review
    const [ showModal, setShowModal ] = useState(false); // determine if modal should show
    const [ search, setSearch ] = useState('');
    const [ status, setStatus ] = useState('');
    function closeModal(refresh) {
        if (refresh) getOrganizationCodes(organization.id, codes.page, search, status)
            .catch(e => console.log(e));

        setSingleCode(null);
        setShowModal(false);
    }

    function openEditModal(code) {
        setSingleCode(code);
        setShowModal(true);
    }

    function changePage ({ selected }) {
        const page = selected+1;
        setPage(page);
        getOrganizationCodes(organization.id, page, search, status);
    }

    function searchCodes(e) {
        e.preventDefault();
        setPage(1);
        setStatus('');
        getOrganizationCodes(organization.id, 1, search);
    }

    // fetch codes on mount
    useEffect(() => {
        getOrganizationCodes(organization.id, codes.page)
            .then(() => setLoading(false))
            .catch(e => console.log(e));
    }, []);

    useEffect(() => {
        setPage(1);
        getOrganizationCodes(organization.id, 1, search, status);
    }, [status]);
    if (loading) {
        return (
            <div className="margin-top-lrg">
                <Loading/>
            </div>
        )
    }
    return (
        <div>
            <div className='row margin-top-2x'>
                <div className="col-xs-12 col-sm-8">
                    <h2 className='m:0'>Codes</h2>
                </div>
                <div className={`col-xs-12 col-sm-4 ${styles.addButton}`}>
                    <PrimaryButton onClick={()=>setShowModal(true)}>
                        + Add New Code
                    </PrimaryButton>
                </div>
            </div>
            <form onSubmit={searchCodes} className="row margin-top-2x">
                <div className="col-xs-12">
                    <div className="form-group">
                        <label htmlFor="codeSearch">
                            Search by name
                        </label>
                        <div className={styles.search}>
                            <input
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                className={`form-control ${styles.searchInput}`}
                                type="text"/>
                            <BasicButton type='submit' onClick={searchCodes} className={styles.searchButton}>Search</BasicButton>
                        </div>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-xs-12 col-sm-10">
                    <h3>Codes</h3>
                    <p className="m:0">[widget helpful description here]</p>
                </div>
                <div className="col-xs-12 col-sm-2">
                    <div className='form-group'>
                        <label>Filter by:</label>
                        <select
                            className='form-control'
                            value={status}
                            onChange={e=>setStatus(e.target.value)}>
                            <option value="">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                <Table striped bordered hover size="sm">
                    <colgroup>
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '25%' }} /> {/* Expiration Date column */}
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                    </colgroup>
                    <thead className="tile-header">
                        <tr>
                        <th>Status</th>
                        <th>Code</th>
                        <th>Trial Length</th>
                        <th>Expiration Date</th>
                        <th>Action</th>
                        <th>Used</th>
                        </tr>
                        </thead>
                        <tbody>
                        { codes.codes.map(code => (
                            <tr>
                                <td>{code.status}</td>
                                <td>{code.code}</td>
                                <td>
                            {code.trial_length
                                ? `${code.trial_length} ${code.trial_length === 1 ? 'Month' : 'Months'}`
                                : ''}
                            </td>
                                <td>{ moment(code.expiration_date).isValid() ?
                                        `${moment(code.expiration_date).format('MM/DD/yyyy hh:mm A')} CST`
                                        :
                                        'N/A'
                                }</td>
                                <td onClick={() => openEditModal(code)} className='hover t-d:u'>Edit</td>
                                <td>{code.used}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="pagination-container">
                <GeneralPagination pages={codes.pages} onPageChange={changePage}/>
            </div>
            {
                showModal ?
                    <CodeModal
                        organization={organization}
                        code={singleCode}
                        show={showModal}
                        onClose={closeModal}
                    />
                    : null
            }
        </div>
    )
}

function mapStateToProps({ organizationCodes }) {
    return {
        codes: organizationCodes,
    }
}

const actions = {
    getOrganizationCodes,
    setPage: setOrganizationCodePage,
}

export default connect(mapStateToProps, actions)(OrganizationCodes);

function CodeModal({ organization, code, onClose, show }){
    const constraints = {
        code: {
            presence: true,
            length: {
                minimum: 1,
                maximum: 255,
                tooShort: '^Please enter a valid code',
                tooLong: '^Code must be less than 255 characters',
            },
            type: 'string'
        },
        expiration: {
            presence: true,
            datetime: {
                dateOnly: true,
                earliest: moment.utc().subtract(1, 'day'),
                message: "^Expiration date cannot be earlier than today"
            }
        },
        trialMonths: {
            presence: true,
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                message: "^Trial length must be a positive integer "
            }
        }
    };

    const [ editCode, setEditCode ] = useState({
        id: code ? code.id : null,
        code: code ? code.code : '',
        expiration: code && moment(code.expiration_date).isValid() ? moment(code.expiration_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        log: code ? code.log : [],
        status: code ? code.status : null,
        used: code ? code.used : 0,
        trialMonths: code && code.trial_months ? code.trial_months : "1",


    });
    const [ submitError, setSubmitError ] = useState('');
    const [ validation, setValidation ] = useState({});
    const [ confirmShow, setConfirmShow ] = useState(false);
    const [ historyHeight, setHistoryHeight ] = useState(0);
    const [ loading, setLoading ] = useState(false);

  

    function submit(e) {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        const errors = {};
        const valid = validate({ code: editCode.code, expiration: editCode.expiration, trialMonths: editCode.trialMonths}, constraints);
        errors.codeValidation = valid;
        setValidation(errors);
        if (valid) {
            setLoading(false);
            return;
        }

       if (!editCode.id) {
           // create code
        axios.post(`/organizations/code/create/${organization.id}`, {
               code: editCode.code,
               expiration_date: editCode.expiration,
               trial_months:editCode.trialMonths,
           })
               .then(() => {
                   setLoading(false);
                   onClose(true)
               })
               .catch(e => {
                if (e.response && e.response.data) {
                setSubmitError(e.response.data.error);
                } else {
                    setSubmitError('Unable to create code');
                }
               });
       } else {
           axios.post(`/organizations/code/edit/${organization.id}`, {
               code_id: editCode.id,
               code: editCode.code,
               expiration_date: editCode.expiration,           
           })
               .then(() => {
                   setLoading(false);
                   onClose(true)

               })
               .catch(e => {
                   if (e.response && e.response.data) {
                     setSubmitError(e.response.data.error);
                   } else {
                       setSubmitError('Unable to save code');
                   }
               });
       }
    }

    function deactivate() {
        axios.post(`/organizations/code/deactivate/${organization.id}`, { code_id: editCode.id })
            .then(() => {
                onClose(true);
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setSubmitError(e.response.data.error);
                } else {
                    setSubmitError('Unable to deactivate code');
                }
            })
    }

    function showHistory() {
        if (historyHeight === 0) {
            const approximateHeight = `${(40*(editCode.log.length))+100}px`;
            setHistoryHeight(approximateHeight);
        } else {
            setHistoryHeight(0);
        }
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { submitError ?
                    <ErrorAlert>{submitError}</ErrorAlert>
                    : null
                }
                <form onSubmit={submit}>
                    { editCode.used > 0 ? <ErrorAlert>Code has been used and can no longer be altered</ErrorAlert> : null }
                    <div className="form-group">
                        <label htmlFor="editCode">Code</label>
                        <input
                            disabled={editCode.used > 0}
                            value={editCode.code}
                            onChange={e=>setEditCode({ ...editCode, code: e.target.value })}
                            className='form-control'
                            type="text"
                            id="editCode"/>
                        { validation.codeValidation && validation.codeValidation.code ?
                            <div>
                                { validation.codeValidation.code.map(err => (
                                    <span className="d:b text-danger">{err}</span>
                                ))}
                            </div>
                            : null
                        }
                    </div>
                    {organization.sales_channel === "retail" && !editCode.id && (
                        <div className="form-group">
                            <label htmlFor="trialMonths">Trial Length (Months)</label>
                            <input
                                disabled={editCode.used > 0}
                                value={editCode.trialMonths}
                                onChange={e => setEditCode({ ...editCode, trialMonths: e.target.value })}
                                className='form-control'
                                type="number"
                                id="trialMonths"
                                min="1"
                                max="60"
                            />
                            {validation.codeValidation && validation.codeValidation.trialMonths ? (
                                <div>
                                    {validation.codeValidation.trialMonths.map(err => (
                                        <span className="d:b text-danger" key={err}>{err}</span>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    )}
                    <div className="form-group">
                        <label htmlFor="expiration">Expiration Date</label>
                        <p><i>Note: all codes expire on this date at 11:59:59pm CST</i></p>
                        <input
                            value={editCode.expiration}
                            onChange={e=>setEditCode({ ...editCode, expiration: e.target.value })}
                            className='form-control'
                            type="date"
                            id="expiration"/>
                        { validation.codeValidation && validation.codeValidation.expiration ?
                            <div>
                                { validation.codeValidation.expiration.map(err => (
                                    <span className="d:b text-danger">{err}</span>
                                ))}
                            </div>
                            : null
                        }
                    </div>
                    <div className={styles.formFooter}>
                        { editCode.status === 'active' ?
                            <BasicButton onClick={()=>setConfirmShow(!confirmShow)} className={styles.deactivate}>Deactivate</BasicButton>
                            : null
                        }
                    </div>
                    <div style={confirmShow ? { display: 'flex' } : {}} className={styles.confirm}>
                        <p className="text-danger">Deactivation can't be undone. Please confirm action.</p>
                        <DangerButton onClick={deactivate}>Confirm</DangerButton>
                    </div>
                </form>
                <div>
                    <BasicButton onClick={showHistory} className={styles.history}>History</BasicButton>
                    <div style={{ maxHeight: historyHeight }} className={styles.historyContainer}>
                        { editCode.log ? <CodeHistory history={editCode.log}/> : null }
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <DangerButton className='m-r:1' onClick={onClose}>Close</DangerButton>
                <PrimaryButton onClick={submit}>Save</PrimaryButton>
            </Modal.Footer>
        </Modal>
    )
}

function CodeHistory({ history }) {
    return (
        <Table striped bordered hover size="sm">
            <thead className="tile-header">
            <tr>
                <th className='col-xs-3'>User</th>
                <th className='col-xs-3'>Code</th>
                <th className='col-xs-3'>Action</th>
                <th className='col-xs-3'>Date</th>
            </tr>
            </thead>
            <tbody>
            { history.map(log => (
                <tr>
                    <td>{log.user}</td>
                    <td>{log.value}</td>
                    <td>{log.action}</td>
                    <td>{moment(log.date).format('MM-DD-YYYY')}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
}