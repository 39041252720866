import React, { useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import styles from './CardDetailsForm.module.css'; 

const CardDetailsForm = ({ postalCode, setPostalCode }) => {
  return (
    <div style={{ marginTop: '10px', maxWidth: "400px" }}>
      <div className={styles.cardInput}>
        <label className={styles.label}>Card Number</label>
        <div className={styles.inputWrapper}>
          <CardNumberElement />
        </div>
      </div>

      <div className={styles.flexContainer}>
        <div className={styles.cardInput}>
          <label className={styles.label}>Expiration Date</label>
          <div className={styles.inputWrapper}>
            <CardExpiryElement />
          </div>
        </div>
        <div className={styles.cardInput}>
          <label className={styles.label}>CVC</label>
          <div className={styles.inputWrapper}>
            <CardCvcElement />
          </div>
        </div>
        {/* ZIP Code input field */}
        <div className={styles.cardInput}>
          <label className={styles.label}>ZIP Code</label>
          <input
            className={styles.zipCode}
            placeholder="00000"
            type="text"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default CardDetailsForm;
