import React from "react";
import styles from "./PaginationFooter.module.css";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const handleClick = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pages = [];
    let startPage;
    let endPage;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    if (startPage < 1) startPage = 1;
    if (endPage > totalPages) endPage = totalPages;

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <span
          key={i}
          onClick={() => handleClick(i)}
          className={`${styles.pageNumber} ${
            currentPage === i ? styles.active : ""
          }`}
        >
          {i}
        </span>
      );
    }
    return pages;
  };

  return (
    <div className={styles.paginationContainer}>
      {currentPage > 1 && (
        <span
          onClick={() => handleClick(currentPage - 1)}
          className={styles.arrow}
        >
          &laquo;
        </span>
      )}
      {renderPageNumbers()}
      {currentPage < totalPages && (
        <span
          onClick={() => handleClick(currentPage + 1)}
          className={styles.arrow}
        >
          &raquo;
        </span>
      )}
    </div>
  );
};

export default Pagination;
