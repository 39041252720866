import React from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import NewGym from './NewGym';
import ModifyGym from './ModifyGym';
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {BasicButton} from "../../buttons/buttons";

class Gym extends React.Component {
    constructor(){
        super();
        this.state={
          name: '',
          street: '',
          city: '',
          gymState: '',
          zipcode: null,
          website: '',
          show: false,
          alertStyle: true,
          alert: '',
          gymArr:[],
          gymArrNew:[],
          showAdd:true,
        }
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    }

  goBack() {
    window.history.back();
  }

    componentDidMount() {
      this.getGymList();
    }

  getGymList(){
      this.setState({showAdd:true})
      this.setState({gymArr:[]})
      this.setState({gymArrNew:[]})
      axios.get(`/user/client-gym/${this.props.username}`)
      .then((res)=> {
        this.setState({gymArr:res.data.result})
      })
        .catch((error)=>{
           console.log(error);
        });
  }

  nameChangeHandler = (name) => {
    this.setState({name:name})
  }

  streetChangeHandler = (street) => {
    this.setState({street:street})
  }

  cityChangeHandler = (city) => {
    this.setState({city:city})
  }

  stateChangeHandler = (gymState) => {
    this.setState({gymState:gymState})
  }

  zipcodeChangeHandler = (zipcode) => {
    this.setState({zipcode:zipcode})
  }

  websiteChangeHandler = (website) => {
    this.setState({website:website})
  }

  updateChangeHandler = (value) => {
      if(value === true){
        this.getGymList();
      }
  }

  modifyGymList=()=>{
        if(this.state.gymArr){
            return this.state.gymArr.map((item, i)=>{
              let gym = this.state.gymArr[i];
                   return(
                    <BoxContainer className='padding-standard margin-bottom-med'>
                        <ModifyGym
                            name={gym.name}
                            street={gym.street}
                            city={gym.city}
                            gymState={gym.state}
                            zipcode={gym.zipcode}
                            website={gym.website}
                            id={gym.id}
                            length={this.state.gymArr.length}
                            nameChangeHandler={this.nameChangeHandler}
                            streetChangeHandler={this.streetChangeHandler}
                            cityChangeHandler={this.cityChangeHandler}
                            stateChangeHandler={this.stateChangeHandler}
                            zipcodeChangeHandler={this.zipcodeChangeHandler}
                            websiteChangeHandler={this.websiteChangeHandler}
                            username={this.props.username}
                            updateChangeHandler={this.updateChangeHandler}
                        />
                    </BoxContainer>
                   )
            });
        }
    };

  newGymList=()=>{
        if(this.state.gymArrNew){
            return this.state.gymArrNew.map(()=>{
                   return(
                    <BoxContainer className='padding-standard margin-bottom-med'>
                        <NewGym
                            username={this.props.username}
                            updateChangeHandler={this.updateChangeHandler}
                        />
                    </BoxContainer>
                   )
            });
        }
    };

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  accountUpdate(e) {
        e.preventDefault();
      axios.post(`/user/client-gym/${this.props.username}/add`, {
        name: this.state.name,
        street: this.state.street,
        city: this.state.city,
        state: this.state.gymState,
        zipcode: this.state.zipcode,
        website: this.state.website,
      })
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.setState({showAdd:true})
                this.setState({ show: true });
                this.setState({ alert: 'success' });
                this.setState({ alertStyle: true });
                this.getGymList();
            }})
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
    }

    addItem(e){          
        const obj = {'name':'', };
        this.setState({
            gymArrNew: [...this.state.gymArrNew, obj]
        }, () => {this.setState({showAdd:false})});    
    }

	render() {
    let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
    return (
        <div>
          <form onSubmit={(e)=>this.accountUpdate(e)}>
              <div>
                <div>
                  {this.modifyGymList()}
                  {this.newGymList()}
                </div>
                {this.state.gymArr.length >= 10 || this.state.showAdd === false
                  ? null
                  : <BasicButton className="btn add--icon" onClick={(e)=>this.addItem(e)}>ADD MORE</BasicButton>
                }
                  {this.state.show
                      ?  <Alert className="save-profile-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                          <p>{this.state.alert}</p>
                      </Alert>
                      : null
                  }
              </div>
          </form>
      </div>
    );
  }
}

export default withRouter(Gym);
