import React from "react";

// styles
import styles from "./InvoiceDetails.module.css";
// icos
import { FiDownload } from "react-icons/fi";

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  if (isNaN(date)) return "";
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

const formatCurrency = (value) => {
  if (value == null || isNaN(value)) return "";
  return `$${Number(value).toFixed(2)}`;
};

const InvoiceDetails = ({ invoice, onBack }) => {
  if (!invoice) return null; // Handle case when no invoice is selected

  const address = invoice.address || {};

  return (
    <div className={styles.invoiceContainer}>
      <div className={styles.titleContainer}>
        <div>{invoice.number && <h4>Invoice {invoice.number}</h4>}</div>
        {invoice.invoice_pdf && (
          <div className={styles.downloadContainer}>
            <p className={styles.downloadText}>Download</p>
            <span>
              <a
                href={invoice.invoice_pdf}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiDownload size={21} color={"lightgrey"} />
              </a>
            </span>
          </div>
        )}
      </div>

      <hr />

      {invoice.date && (
        <p>
          <strong>Date of Issue:</strong> {formatDate(invoice.date)}
        </p>
      )}
      {(invoice.dueDate || invoice.date) && (
        <p>
          <strong>Date Due:</strong>{" "}
          {formatDate(invoice.dueDate || invoice.date)}
        </p>
      )}

      <hr />

      {/* Billing Information */}
      {invoice.name && (
        <>
          <p className={styles.companyName}>Bill to</p>
          <p>{invoice.name}</p>
          {address.line1 && <p>{address.line1}</p>}
          {address.line2 && <p>{address.line2}</p>}
          {(address.city || address.state || address.postal_code) && (
            <p>
              {address.city || ""}
              {address.city && address.state && ", "}
              {address.state || ""} {address.postal_code || ""}
            </p>
          )}
          {address.country && <p>{address.country}</p>}
          {invoice.email && <p>{invoice.email}</p>}
        </>
      )}

      <hr />

      {invoice.payment_url && (
        <a href={invoice.payment_url} className={styles.payOnlineLink}>
          Pay Online
        </a>
      )}

      {/* Invoice Items */}
      {invoice.description || invoice.quantity || invoice.total ? (
        <table className={styles.invoiceTable}>
          <thead>
            <tr>
              <th className={styles.leftAlign}>Description</th>
              <th className={styles.rightAlign}>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{invoice.description || "Treo Subscription"}</td>
            </tr>
          </tbody>
          <tfoot>
            {invoice.subtotal != null && (
              <tr>
                <td className={styles.subtotalLabel}>Subtotal</td>
                <td className={styles.rightAlign}>${invoice.subtotal}</td>
              </tr>
            )}
            {invoice.tax != null && (
              <tr>
                <td className={styles.subtotalLabel}>Tax</td>
                <td className={styles.rightAlign}>${invoice.tax}</td>
              </tr>
            )}
            {invoice.total != null && (
              <tr>
                <td className={styles.subtotalLabel}>Total</td>
                <td className={styles.rightAlign}>${invoice.total}</td>
              </tr>
            )}
          </tfoot>
        </table>
      ) : null}

      <hr />

      {/* Final Amount Due */}
      {invoice.total != null && (
        <p className={styles.amountDue}>
          Amount Due: {formatCurrency(invoice.total)}
        </p>
      )}
    </div>
  );
};

export default InvoiceDetails;
