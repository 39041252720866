// HamburgerMenu.js
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./HamburgerMenu.module.css";
import {
  FaBars,
  FaTimes,
  FaChevronRight,
} from "react-icons/fa";
import { ReactComponent as BackArrow } from "../../../images/icons/back_arrow_long_tail.svg";


const HamburgerMenu = ({ links }) => {
  const [open, setOpen] = useState(false);
  const [menuStack, setMenuStack] = useState([links]);
  const menuRef = useRef(null);

  const toggleMenu = () => setOpen(!open);

  const closeMenu = () => {
    setOpen(false);
    setMenuStack([links]); // Reset to the main menu
  };

  const navigateToSubmenu = (subLinks) => {
    setMenuStack([...menuStack, subLinks]);
  };

  const navigateBack = () => {
    if (menuStack.length > 1) {
      setMenuStack(menuStack.slice(0, -1));
    } else {
      closeMenu();
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (open && menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [open]);

  const renderLinks = (currentLinks) => (
    <ul className={styles.hamburgerLinks} ref={menuRef}>
      {menuStack.length > 1 && (
        <li className={styles.backIcon} onClick={navigateBack}>
          <BackArrow style={{ fill: "Black", width: 40 || "unset" }} />
        </li>
      )}

      {menuStack.length <= 1 && (
        <li className={styles.closeIcon} onClick={closeMenu}>
       <BackArrow style={{ fill: "Black", width: 40 || "unset" }} />
        </li>
      )}

      {currentLinks.map((link, index) => (
        <li key={index} className={styles.linkItem}>
          {link.subLinks ? (
            <div
              className={styles.hamburgerLinkLabel}
              onClick={() => {
                if (link.navigateToSubmenu) {
                  // Navigate to a new submenu
                  navigateToSubmenu(link.subLinks);
                } else {
                  // Expand/collapse sublinks
                  link.onClick && link.onClick();
                }
              }}
            >
              {link.label}
              <FaChevronRight />
            </div>
          ) : link.href ? (
            <Link to={link.href} onClick={closeMenu} className={styles.link}>
              {link.label}
              <FaChevronRight size={16} />
            </Link>
          ) : (
            <span
              onClick={() => {
                link.onClick && link.onClick();
                closeMenu();
              }}
              className={styles.link}
            >
              {link.label}
            </span>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <div className={styles.hamburgerMenu}>
      <div
        onClick={toggleMenu}
        className={styles.hamburgerIcon}
        aria-label="Toggle menu"
        aria-expanded={open}
        role="button"
        tabIndex={0}
      >
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "8px"}}>
        {open ?       <BackArrow style={{ fill: "black", width: "35px"  || "unset" }} /> : <FaBars />}
        <span style={{marginRight: "10px", fontSize: "16px"}}>MENU</span>
        </div>

      </div>
      {open && (
        <div className={styles.menuOverlay}>
          {renderLinks(menuStack[menuStack.length - 1])}
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
