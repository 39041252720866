export function getPrices(product) {
  return product.prices || [];
}

export function getPlanTitle(price) {
  if (price.label && price.label.toLowerCase().includes("year"))
    return "Annually";
  if (price.label && price.label.toLowerCase().includes("month"))
    return "Monthly";
  return "Unknown Plan";
}

export function getPlanSubtitle(price) {
  if (price.label && price.label.toLowerCase().includes("year"))
    return "Yearly";
  if (price.label && price.label.toLowerCase().includes("month"))
    return "Monthly";
  return "";
}

export function getPlanDuration(price) {
  if (price.label && price.label.toLowerCase().includes("year"))
    return "Yearly";
  if (price.label && price.label.toLowerCase().includes("month"))
    return "Monthly";
  return "term";
}

/**
 * Finds the first monthly plan option in a list of products.
 * @param {Array} products - An array of product objects, each containing a prices array.
 * @returns {Object|null} The monthly plan option object, or null if none found.
 */
export function findMonthlyPlanOption(products) {
  for (const product of products) {
    const prices = getPrices(product);
    const monthlyPrice = prices.find(
      (price) => price.label && price.label.toLowerCase().includes("month")
    );

    if (monthlyPrice) {
      return {
        price_id: monthlyPrice.price_id,
        price: monthlyPrice.price,
        title: getPlanTitle(monthlyPrice),
        billingCycle: `/ ${getPlanDuration(monthlyPrice)}`,
        duration: getPlanSubtitle(monthlyPrice),
        value: monthlyPrice.price_id,
      };
    }
  }

  return null;
}

export function buildOptionFromPrice(price) {
  return {
    price_id: price.price_id,
    price: price.price,
    title: getPlanTitle(price),
    billingCycle: `/ ${getPlanDuration(price)}`,
    duration: getPlanSubtitle(price),
    value: price.price_id,
  };
}

export function determineSubmitDisabled(selectedOption, paymentDate, today) {
  if (!selectedOption) return true;
  if (
    selectedOption.value === "close-account" ||
    selectedOption.value === "resume-subscription"
  ) {
    return false;
  }
  if (paymentDate && new Date(paymentDate) > today) {
    return true;
  }
  return false;
}
