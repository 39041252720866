import mainApi from "./mainApi";

export function stripeApi() {
    return {
        createStripeCustomer: async function(userInfo) {
            return mainApi.post(`/stripe/create/customer`, userInfo);
        },

        
        createStripeSubscription: async function(price_id) {
            const response = await mainApi.post(
                '/stripe/create/subscription', 
                { price_id}, 
              );
              return response.data;
        },

        getStripeMemberDetails: async function() {
            const response = await mainApi.get(
                            '/stripe/active/subscription'  );
                          return response.data;
        },
            
        getUserActiveStripeProduct: async function() {
            const response = await mainApi.get(
                '/stripe/products/active'  );
              return response.data;
        },

        postCancelStripeSubscription: async function(subscription_id)  {
            const response = await mainApi.post(
                '/stripe/cancel/subscription', {subscription_id});
                return response.data;

        },


        postUncancelStripeSubscription : async function(subscription_id) {
            const response = await mainApi.post(
                '/stripe/uncancel/subscription',
                { subscription_id }
            );
            return response.data;
        },

        getUserInvoices: async function (limit = 10, last_id = null) {
            const params = { limit };
            if (last_id) params.last_id = last_id;
          
            const response = await mainApi.get(`/stripe/customer/invoices`, { params });
            return response.data;
          },
          
          assignNewCardToSubscription: async function (sub_id, pm_id) {
            const response = await mainApi.post(
                '/stripe/new/card', 
                { sub_id, pm_id }
            );
            return response.data;
        },

        getUserTrialInformation: async function () {
            const response = await mainApi.get('/user/retail/trial')
             return response.data;

        },
        

        postCheckoutSession: async function(price_id) {
            const response = await mainApi.post(
                '/stripe/create/checkout/session', {
                    price_id
                }
            );
            return response.data;
        },

        getSessionStatus: async function (session_id) {
            if (!session_id) {
                throw new Error("session_id is required");
            }
            const response = await mainApi.get(`/stripe/session/status`, {
                params: { session_id },
            });
            return response.data;
        },
        
        postRequestStripeRefund: async function (comments) {
            const response = await mainApi.post('/stripe/refund/request',{comments})

            return response.data;
        },
      


    }
}



