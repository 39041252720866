import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Redux & Actions
import { stripeApiMap } from "../../../../../store/actions/stripeActions";

// Components & Styles
import { PrimaryButton, SecondaryButton } from "../../../../buttons/buttons";
import ClientManageSubscriptionModal from "../../components/manageSubscriptionModule/ClientManageSubscriptionModal";
import PlanOption from "../../components/planOption/PlanOption";
import SkeletonText from "../../components/Loading/SkeletonText";
import TermsOfService from "../../../../signUp/termsOfService/termsOfService";
import styles from "./paymentDetails.module.css";

// Icons
import { ReactComponent as SuccessPayment } from "../../../../../images/icons/SuccessPayment.svg";
import { ReactComponent as DeleteAccountManagement } from "../../../../../images/icons/DeleteAccountManagement.svg";

// Utilities
import { formatDate } from "../../utils/dateUtils";
import {
  findMonthlyPlanOption,
  buildOptionFromPrice,
  determineSubmitDisabled,
} from "../../utils/planUtils";

function ClientPlanOptionForm(props) {
  const {
    setSelectedOption,
    selectedOption,
    handleManageSubscription,
    stripePlanInformation,
    paymentDate,
    showPaymentInfo,
    setShowPaymentInfo,
    hideDesktopTabs
  } = props;

  // action
  const { stripeApiMap, user} = props

  const {
    subscription_id: subscriptionId,
    cancel_at_period_end: uncancelPayment,
    cancel_at,
    subscription
  } = stripePlanInformation || {};
  const renewalDate = cancel_at ? formatDate(cancel_at) : null;
  
  // States
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [policy, setPolicies] = useState([]);
  const today = new Date();


  
  // Data Fetch
  const { getUserActiveStripeProduct, postCancelStripeSubscription, postUncancelStripeSubscription } = stripeApiMap();
  
  const getUserSubscriptionInfo = async () => {
    setLoading(true);
    try {
      const response = await getUserActiveStripeProduct();
      if (response && response.result) {
        const result = response.result;
        const productsArray = Array.isArray(result) ? result : [result];
        setProducts(productsArray);
      } else {
        console.error("Failed to fetch products from API response");
      }
    } catch (error) {
      console.error("Error fetching subscription info:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserSubscriptionInfo();
  }, [subscriptionId, user.stripe_id]);

  useEffect(() => {
    const matchedOption = findMonthlyPlanOption(products);
    if (matchedOption) {
      setSelectedOption(matchedOption);
    } 
  }, [products, setSelectedOption]);

  useEffect(() => {
    setIsSubmitDisabled(determineSubmitDisabled(selectedOption, paymentDate, today));
  }, [selectedOption, paymentDate, today]);

  // Handlers
  const handleSubmit = () => {
    if (!isSubmitDisabled) {
      if (selectedOption && (selectedOption.value === "close-account" || selectedOption.value === "resume-subscription")) {
        setShowModal(true);
      } else {
         handleManageSubscription();
      }
    }
  };

  const handleFinish = async () => {
    setIsFinishing(true);
    try {
      if (selectedOption.value === "close-account") {
        await postCancelStripeSubscription(subscriptionId);
      } else if (selectedOption.value === "resume-subscription") {
        await postUncancelStripeSubscription(subscriptionId);
      }
      window.location.reload();
      setIsFinishing(false);
      setShowConfirmationModal(false);
    } catch (error) {
      setIsFinishing(false);
      console.error("Error processing subscription action:", error);
    }
  };

  const toggleConfirmationModal = () => {
    setShowModal(false);
    setShowConfirmationModal(true);
  };

  const modifiedHandleOptionChange = (option) => {
    setSelectedOption(option);
  };

  let buttonText = "Submit";

  if (loading) {
    buttonText = "Loading...";
  } else if (hideDesktopTabs) {
    buttonText = "Start Membership";
  }

  // Helper Components
  const PlanOptionsLoading = () => (
    <SkeletonText height="70px" />
  );

  // renders checkbox form with stripes plan title and value
  const renderPlanOptions = () => (
    products.map((product) =>
      product.prices.map((price) => {
        const option = buildOptionFromPrice(price);
        return (
          <PlanOption
            key={price.price_id}
            title={option.title}
            subtitle={`Billed ${option.duration}`}
            priceText={`$${price.price.toFixed(2)} USD${option.billingCycle}`}
            value={option.value}
            selectedOption={selectedOption}
            handleOptionChange={() => modifiedHandleOptionChange(option)}
            disabled={!price.price_id}
            loading={loading}
            hideDesktopTabs={hideDesktopTabs} 
          />
        );
      })
    )
  );
  const shouldHideCancelButtonOrOption = 
  (user.user_status_id === 10 && user.organization.sales_channel === "retail") 
   || subscription === null;

  return (
    <div>
      <div className={styles.subscriptionsAndSelectContainer}>
        {loading ? <PlanOptionsLoading /> : renderPlanOptions()}
        {/* display close account only if its not first signup or trialexpired */}
        {!shouldHideCancelButtonOrOption && (
        <PlanOption
          title={uncancelPayment ? "Resume Subscription" : "Close Account"}
          priceText={
            <em className={styles.planOptionEmphasis}>
              {uncancelPayment
                ? `Pause scheduled to begin on ${renewalDate}`
                : "Access ends at next billing cycle"}
            </em>
          }
          value={uncancelPayment ? "resume-subscription" : "close-account"}
          selectedOption={selectedOption}
          handleOptionChange={() =>
            modifiedHandleOptionChange({
              value: uncancelPayment
                ? "resume-subscription"
                : "close-account",
              title: uncancelPayment
                ? "Resume Subscription"
                : "Close Account",
            })
          }
          disabled={false}
          loading={loading}
        />
      )}
      </div>

      <div className={styles.subscriptionContainer}>
        <p>
          <strong className={styles.subscriptionTermsTitle}>
            Subscription Terms:
          </strong>
          <span className={styles.subscriptionTermsText}>
            The subscription automatically renews at your selected rate, plus applicable taxes, unless you cancel before the next billing cycle.
             Subscription payments will be charged to your account. Cancel at anytime.
          </span>
        </p>
      </div>

      <div className={styles.btnContainer}>
      {!shouldHideCancelButtonOrOption && (
        <SecondaryButton
          type="button-outline"
          onClick={() => showPaymentInfo && setShowPaymentInfo(!showPaymentInfo)}
          className={styles.cancelButton}
        >
          Cancel
        </SecondaryButton>
        )}
        <PrimaryButton
          type="button"
          onClick={handleSubmit}
          disabled={isSubmitDisabled || loading}
        >
     {buttonText}
        </PrimaryButton>
      </div>

      <div className={styles.linksContainer}>
        <TermsOfService
          loadPolicies={setPolicies}
          horizontal
          additionalLink={true}
          darkOverlay={true}
        />
      </div>

      {/*-------------------------- Modals-------------------------------------- */}

      <ClientManageSubscriptionModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={uncancelPayment ? "Are you sure you want to resume your subscription?" : "Are you sure you want to close your account?"}
        buttonText={uncancelPayment ? "Resume Subscription" : "Close Account"}
        handleFinish={toggleConfirmationModal}
        LogoComponent={uncancelPayment ? SuccessPayment : DeleteAccountManagement}
        modalBodyClass={styles.customModalBody}
        showModalPrimaryButton={true}
      >
        {uncancelPayment ? (
          <>
            <p className={styles.customModalSubHeader}>
              Resuming your subscription will:
            </p>
            <ul className={styles.bulletList}>
              <li>Reactivate your access to the platform</li>
              <li>Restore your account and user history</li>
            </ul>
            <p className={styles.customModalSubHeader}>
              Do you want to proceed?
            </p>
          </>
        ) : (
          <>
            <p className={styles.customModalSubHeader}>
              Closing your account will:
            </p>
            <ul className={styles.bulletList}>
              <li>Terminate your access to the platform</li>
              <li>Delete all records from the database</li>
              <li>Erase your user history</li>
            </ul>
            <p className={styles.modalText}>
              Once erased, all historical data <strong>cannot be restored.</strong>
            </p>
          </>
        )}
      </ClientManageSubscriptionModal>

      {showConfirmationModal && (
        <ClientManageSubscriptionModal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
          isFinishing={isFinishing}
          handleFinish={handleFinish}
          title={uncancelPayment ? "Your subscription will be resumed." : "Your account will be deleted."}
          buttonText="Finish"
          LogoComponent={uncancelPayment ? SuccessPayment : DeleteAccountManagement}
          modalBodyClass={styles.customModalBody}
          showModalPrimaryButton={true}
        />
      )}
    </div>
  );
}

const actions = {
  stripeApiMap,
};

function mapStateToProps({ user }) {
  return {
    user,
  };
}

export default connect(mapStateToProps, actions)(ClientPlanOptionForm);
