// utils/dateUtils.js

import { parse, isBefore } from 'date-fns';

export const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const parseDateString = (dateString, format) => {
  return parse(dateString, format, new Date());
};

export const isBeforeDate = (date1, date2) => {
  // Normalize dates to remove time component
  const normalizedDate1 = new Date(date1);
  const normalizedDate2 = new Date(date2);
  normalizedDate1.setHours(0, 0, 0, 0);
  normalizedDate2.setHours(0, 0, 0, 0);

  return isBefore(normalizedDate1, normalizedDate2);
};
